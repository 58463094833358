import React, { useState, useEffect, useRef } from "react";
import { ArrowLeftIcon, ArrowRightIcon, InformationCircleIcon as InformationCircleIcon2, ShieldCheckIcon } from "@heroicons/react/solid";

import {
    BidResponseBookingOverview, BidResponseFooter,
    BidResponseHeader, BidResponseMenuBreakdown, BidResponseRestaurantDetails
} from ".";

export default function BidResponse({ bid, setDrawerWidth, drawerWidth, bidResponse, accepted, interested, setshowDrawer, mobile }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));

    const dietary_preferencesList = bid.dietary_preferences?.map(a => a.preference).filter((i) => (i != "None"))

    function checkIfPreferenceIsInReponse() {
        const preferencesList = bid.dietary_preferences.map(a => a.preference).filter((i) => (i != "None"))
        const responsePreferences = bidResponse.items.flatMap((item, index) => {
            return item.dietary_option?.map((option, index) => option);
        });
        return (preferencesList.some(item => responsePreferences.includes(item)))
    }

    return (
        <div className={`${!mobile && "h-[calc(100vh_-_60px)]"} flex flex-col w-full justify-between`}>
            <div className={`${!mobile && "mt-5"} h-full overflow-y-scroll pb-7 space-y-5 scrollbar-hide`}>
                <BidResponseHeader bid={bid} bidResponse={bidResponse}
                    interested={interested}
                    checkIfPreferenceIsInReponse={checkIfPreferenceIsInReponse}
                    dietary_preferencesList={dietary_preferencesList} />

                <BidResponseRestaurantDetails bid={bid} bidResponse={bidResponse} />

                <BidResponseBookingOverview bid={bid} bidResponse={bidResponse} />

                <BidResponseMenuBreakdown bid={bid}
                    bidResponse={bidResponse} drawerWidth={drawerWidth}
                    setDrawerWidth={setDrawerWidth}
                    checkIfPreferenceIsInReponse={checkIfPreferenceIsInReponse}
                    setshowDrawer={(e) => setshowDrawer(e)}
                    dietary_preferencesList={dietary_preferencesList} />

                <div className="pt-5 border-t">
                    <h6 className="font-productsans-bold text-gray-500 text-xs uppercase mb-4">Guarantee</h6>
                    <div className="flex space-x-3">
                        <ShieldCheckIcon className="h-7 text-green-700" />
                        <p className="andie-description-text text-xs w-[90%] leading-tight">This response is compliant with the terms and conditions of the Andie, and backed by the <b>Andie Guarantee</b>. <a href="/policies/andie-guarantee" className="underline text-blue-600 cursor-pointer">Learn more</a>.</p>
                    </div>
                </div>
            </div>
            <div className="pt-3 flex justify-between items-center border-t">
                <div>
                    <ArrowLeftIcon className="h-5 text-gray-600" />
                </div>
                <BidResponseFooter bid={bid} bidResponse={bidResponse}
                    interested={interested} accepted={accepted} drawerWidth={drawerWidth}
                    setDrawerWidth={setDrawerWidth} setshowDrawer={(e) => setshowDrawer(e)}
                />

                <div>
                    <ArrowRightIcon className="h-5 text-gray-600" />
                </div>
            </div>
        </div>
    )
}

