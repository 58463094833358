import React from 'react';
import { useNavigate } from "react-router-dom";

import bgbid from "../../../assets/images/hero/bg-bids.png";

export default function HeroBids() {
    const navigate = useNavigate();
    return (
        <div className="bg-purple-200 flex flex-col justify-between rounded-2xl p-5 md:p-6 w-full">
            <div className="md:flex">
                <div className="flex flex-col md:w-1/2 lg:w-3/5 my-4 md:mt-0">
                    <div className="bg-purple-400 rounded h-6 px-2.5 inline-flex w-36 items-center justify-center text-xs uppercase font-roboto-bold bg-opacity-30 mb-4">Bids & Responses</div>
                    <h3 className="font-productsans-bold text-black leading-none text-3xl md:text-4xl max-w-md xl:pr-8">Great <span className="text-purple-900">corporate guest experiences</span> start with Andie.</h3>
                    <p className="mt-2 md:mt-4 xl:w-3/4 andie-description-text leading-tight text-gray-900">From office lunches to team outings, client meetings, and more, forward-thinking organizations trust Andie for their corporate dining and catering needs.</p>
                </div>
                <div className="xl:h-60 my-6 md:my-3 md:pl-4 lg:pl-0 md:w-1/2 lg:w-2/5">
                    <img src={bgbid} className="h-full xl:mt-4 xl:scale-125 w-full object-contain rounded-lg" />
                </div>
            </div>
            <div className="my-3.5 xl:my-2 flex space-x-2">
                <div className="flex space-x-2">
                    <div onClick={() => navigate('/bids')} className="andie-dark-button bg-purple-900 hover:bg-purple-800 w-32 text-white">Explore Bids</div>
                </div>
                <div className="flex space-x-2">
                    <div onClick={() => navigate('/responses')} className="andie-dark-button bg-fuchsia-900 hover:bg-fuchsia-800 w-36 text-white">Browse Responses</div>
                </div>
            </div>
        </div>
    )
}; 