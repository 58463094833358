import React, { useState, useEffect } from "react";
import { SidebarRight, SidebarTemplates } from "../../components/navigation";
import { ExploreBids, ExploreHome, ExploreResponses, ExploreTemplates } from ".";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineAdjustments } from 'react-icons/hi';

import { isAuthenticated } from '../../apis/Credentials';
import { BiddingAPIs, AccountsAPI } from "../../apis";

import { Drawer, SpeedDial, filterSearchBids, filterSearchResponses, filterSearchTemplates } from '../../components/common';
import { CreateBidButton } from "../../components/bid/create";
import { HomeIcon } from "@heroicons/react/solid";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export default function Home() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [selectedTab, setSelectedTab] = useState("Home");
    const [authenticated, setAuthenticated] = useState(null);
    const navigate = useNavigate();

    const [globalFilters, setGlobalFilters] = useState({
        discover: null,
        guests: null,
        budget: null,
        event: null,
        cuisines: [],
        restaurant_level: null,
        happening_soon: null,
        popular: null
    });

    const [bids, setBids] = useState([]);
    const [responses, setResponses] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [loadingBids, setLoadingBids] = useState(true);
    const [viewVideoModal, setViewVideoModal] = useState(false);

    const [searchData, setSearchData] = useState({});

    let id = useParams();

    // const tabs = ["Home", "Bids", "Responses", "Collections"];

    const tabs = ["Home", "Bids"];

    useEffect(() => {
        if (isAuthenticated()) {
            setAuthenticated(true);
            if (user?.account_type === 2) {
                AccountsAPI.VerifyUserOnLogin();
            }
        }
    }, []);

    useEffect(() => {
        const pathParts = id["*"].split('/');
        const navigatedTab = pathParts[0].charAt(0).toUpperCase() + pathParts[0].slice(1);

        if (pathParts[0] === 'ref') {
            AccountsAPI.catchURLLead(pathParts[1]);
        }

        if (!tabs.includes(navigatedTab)) {
            navigate('/home');
        }

        setSelectedTab(navigatedTab);
        handleFilterData(navigatedTab);
    }, [id]);

    useEffect(() => {
        refreshInfo();
    }, []);

    function handleFilterData(tab) {
        if (tab === "Collections") {
            setFilteredData(searchData.category === "Collections" ? filterSearchTemplates(templates, searchData.terms) : templates);
        }
        if (tab === "Bids") {
            setFilteredData(searchData.category === "Bids" ? filterSearchBids(bids, searchData.terms) : bids);
        }
        if (tab === "Responses") {
            const flattenedData = bids.flatMap(bid => bid.responses.map(response => ({ guest_count: bid.guest_count, ...response })));
            setFilteredData(searchData.category === "Responses" ? filterSearchResponses(flattenedData, searchData.terms) : flattenedData);
        }
    }

    const onCreateBid = () => {
        navigate('/cge/create-a-bid', { state: { item: {} } });
    };

    async function getBids() {
        try {
            console.log("Fetching bids...");
            const result = await BiddingAPIs.getBids();
            console.log("Bids received:", result);

            setBids(result);
            const flattenedData = result.flatMap(bid => bid.responses.map(response => ({ bid: bid, guest_count: bid.guest_count, ...response })));
            setResponses(flattenedData);
        } catch (error) {
            console.error("Error fetching bids:", error);
        } finally {
            setLoadingBids(false);
        }
    }

    async function getTemplates() {
        try {
            console.log("Fetching templates...");
            const res = await BiddingAPIs.getCollections();
            console.log("Templates received:", res);

            setTemplates(res.data);
        } catch (error) {
            console.error("Error fetching templates:", error);
        } finally {
            setLoadingTemplates(false);
        }
    }

    function refreshInfo() {
        getBids();
        getTemplates();
    }

    if (loadingBids || loadingTemplates) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-lg font-semibold">Loading...</p>
            </div>
        );
    }

    return (
        <>
            <ModalVideo
                autoplay
                channel="custom"
                isOpen={viewVideoModal}
                url="https://plk24and18xrb21x0.s3.ca-central-1.amazonaws.com/meet-pluck-introductory-video.mp4"
                onClose={() => setViewVideoModal(false)}
            />
            <div className="flex h-[calc(100dvh_-_40px)] w-full">
                <div className="andie-side-panel">
                    <SidebarTemplates globalFilters={globalFilters} setGlobalFilters={setGlobalFilters} filteredData={filteredData} setFilteredData={setFilteredData} showMenu={showMenu} setShowMenu={setShowMenu} />
                </div>
                <div className="andie-main-panel relative">
                    <div className="flex items-center justify-between mb-1">
                        <div className="flex w-full justify-between">
                            <div className="w-[120px] h-8 p-1 bg-gray-100 rounded-md flex">
                                <div onClick={() => navigate("/home")} className="cursor-pointer rounded-md px-2.5 flex items-center justify-center">
                                    <HomeIcon className="h-5 md:h-[18px] text-black" />
                                </div>
                                <div className="grid w-full">
                                    {tabs.map((tab, index) => (
                                        tab !== "Home" && (
                                            <div key={index} onClick={() => navigate(`/${tab.toLowerCase()}`)} className={`${selectedTab.toLowerCase() === tab.toLocaleLowerCase() && "bg-white text-blue-600"} text-gray-600 cursor-pointer rounded-md px-2.5 flex font-productsans-medium text-paragraph md:text-xs xl:text-sidebar items-center justify-center`}>
                                                {tab}
                                            </div>
                                            
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedTab.toLowerCase() === "home" && <ExploreHome bids={bids} templates={templates} responses={responses} />}
                    {selectedTab.toLowerCase() === "collections" && <ExploreTemplates templates={filteredData} />}
                    {selectedTab.toLowerCase() === "bids" && <ExploreBids bids={filteredData} />}
                    {selectedTab.toLowerCase() === "responses" && <ExploreResponses incomingResponses={filteredData} />}
                </div>
                <SidebarRight home />
            </div>
        </>
    );
}
