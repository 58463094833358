import React, { useEffect, useState } from "react";
import { BreakfastIcon, DrinksIcon, ReferralGiftIcon } from "../../../assets/icons";
import { GiftIcon } from "@heroicons/react/solid";

export default function ExploreOffersBanner() {
    const d = new Date();
    var lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);

    const offers = [
        {
            title: "Start the day with breakfast",
            description: "Enjoy a complimentary team breakfast when you complete five bids or more this month. This offer is valid and applicable for catering and dining opportunities with a minimum spend of $1,500.",
            minSpend: 700,
            code: "CPT-500",
            Icon: BreakfastIcon
        },
        {
            title: "Happy hour cocktail on us",
            description: "Treat yourself and up to four (4) colleagues to a Happy Hour Cocktail experience on us at one of our premium partner restaurants when your company spends at least $10,000 on Andie.",
            minSpend: 10000,
            code: "HPH-SPE1",
            Icon: DrinksIcon
        },
        {
            title: "Refer and dine in style",
            description: "Enjoy a lunch, happy hour, or dinner experience for you and up to two (2) colleagues when you refer a new business to Andie and they spend a minimum of $7,500 combined on their first two transactions.",
            minSpend: 5000,
            code: "RFTHANKYOU",
            Icon: ReferralGiftIcon
        }
    ];

    const [selectedOffer, setSelectedOffer] = useState(offers[0]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentIndex((prevValue) =>
            prevValue >= offers.length - 1 ? 0 : prevValue + 1
        );
        setSelectedOffer(offers[currentIndex]);
        }, 15000);

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, [offers, currentIndex]);

    function processSelect(index) {
        setSelectedOffer(offers[index]);
        setCurrentIndex(index);
    }

    return (
        <div className={`relative bg-teal-900 andie-padding md:h-[350px] rounded-2xl`}>
            <div className="flex justify-between">
            <div className={`bg-teal-700 rounded mb-4 md:mb-0 h-6 px-2.5 inline-flex items-center justify-center text-xs uppercase font-roboto-medium text-white bg-opacity-30`}>Promotion</div>
            <GiftIcon className="text-teal-700 h-5" />
            </div>
            <div className="mb-7 md:inline-flex md:flex-row-reverse h-[calc(100%_-_24px)]">
                <div className="md:inline-flex md:w-[45%] justify-center">
                    <selectedOffer.Icon height={window.innerWidth <= 768 ? "100px" : "230px"} color="#FFF" />
                </div>
                <div className="md:w-[55%] flex flex-col">
                    <h6 className="mt-5 text-2xl lg:text-3xl font-productsans-bold w-full md:w-4/5 text-white">{selectedOffer.title}</h6>
                    <div className="mt-3 mb-4 w-20 h-0.5 bg-teal-700" />
                    <p className="font-roboto-regular andie-description-text leading-tight text-white">{selectedOffer.description}</p>
                    <p className="mt-4 mb-12 font-roboto-regular text-[10.5px] leading-tight text-white xl:w-4/5">This offer must be redeemed by <span className="font-roboto-bold">{lastDayOfMonth.toLocaleDateString()}</span> at midnight. Final headcount will be determined at the time of redemption based on spend or headcount from qualifying transactions.</p>
                </div>
            </div>
            <div className="absolute flex w-[calc(100%_-_36px)] items-center justify-between bottom-0 mb-6">
                <div className={`w-32 h-8 flex items-center justify-center uppercase text-paragraph text-white font-productsans-bold border-teal-600 border-dashed border-2`}>
                    {selectedOffer.code}
                </div>
                <div className="flex space-x-2">
                    {Array.from(offers, (item, index) => (
                        <div key={index} onClick={() => processSelect(index)} className={`cursor-pointer h-5 w-5 font-roboto-medium text-white text-xs flex items-center justify-center  rounded-full ${currentIndex === index ? "bg-red-500" : "bg-teal-700"}`}>
                            {index+1}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}