import React from "react";
import { CheckCircleIcon, SearchIcon, SupportIcon } from "@heroicons/react/solid";
import { SidebarRight } from "../../components/navigation";
import { CalculatorIcon, SustainabilityIcon } from "../../assets/icons";
import { BsEmojiSmile } from "react-icons/bs";

import businesssupport from "../../assets/images/sustain/businesses-pluck-supports.png";
import fooddrives from "../../assets/images/sustain/plucks-supports-food-banks-and-more.png";
import sustain from "../../assets/images/sustain/how-pluck-is-contributing-to-sustainable-future.jpg";
import sustainabilityheader from "../../assets/images/sustain/plucking-a-better-future-2.png";
import { Link } from "react-router-dom";

export default function Sustainability() {
    const business_types = ["Minority-owned", "New", "Sustainable", "Women-led", "Indigenous", "Small", "Veterans", "Multi-generational", "People with disabilities", "Historical", "Innovative"]

    const sustainability =[
        "Helping source meals with ethically-sourced ingredients.",
        "Providing sustainable package, utensils, and more.",
        "Minimizing waste and redistributing surplus meals."
    ]

    const commitments = [
        { title: "Fostering Prosperity", description: "Andie is committed to serving as a force for good, and ensuring the people, communities, and businesses we come across thrive."},
        { title: "Sustainable Operations", description: "Sustainability is embedded in everything we do at Andie – who we are as a company, and how we drive our business forward."},
        { title: "Protecting the future", description: "We believe that together with our customers and partners, we can play a role in preserving the planet for future generations."}
    ]

    const clickouts = [
        { 
            title: "Help & Support",
            header: "Reach out",
            description: "Talk to us about your corporate, or social responsibility initiatives and how we can be of help?",
            icon: <SupportIcon className="h-6 text-blue-600" />,
            link: "/support"
        },
        { 
            title: "Browse",
            header: "Explore Andie",
            description: "See how organizations are championing corporate and social responsibilities.",
            icon: <SearchIcon className="h-5 text-blue-600" />,
            link: "/bids"
        },
        // { 
        //     title: "Calculator",
        //     header: "Ideal Budget Calculator",
        //     description: "Organizing a sustainable event or booking? Calculate the cost with our ideal budget calculator.",
        //     icon: <CalculatorIcon height={20} color="#2563eb" />,
        //     link: "/pluck-ideal-budget-calculator"
        // }
    ]
    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel" />
                <div className="andie-main-panel overflow-y-scroll scrollbar-hide pb-14">
                    <img src={sustainabilityheader} alt="pluck-sustainability" className="rounded-2xl object-contain w-auto" />

                    <div className="my-8 xl:my-12 grid md:grid-cols-2 gap-4 md:gap-5">
                        <h5 className="andie-page-header md:-mt-1 xl:pr-6">Empowering people, protecting the planet, and fostering happiness.</h5>
                        <div className="md:space-y-5">
                        <p className="andie-description-text leading-tight">At Andie, we are committed to playing our part in building great futures for our communities. Our mission isn't just to connect businesses to great meals and refreshments, or restaurants and caterers to opportunities that significantly impact their bottomline, we strive to create significant impact with our customers and partners.</p>
                        <p className="andie-description-text ont-roboto-medium leading-tight">We believe in backing people and businesses to thrive and create equitable, resilient, happy, and sustainable world.</p>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-3 gap-5">
                        {commitments.map((item, index) => (
                            <div key={index}>
                                <h6 className="uppercase font-productsans-bold text-blue-600 text-sm mb-3">{item.title}</h6>
                                <p className="andie-description-text leading-tight">{item.description}</p>
                            </div>
                        ))}
                    </div>

                    <div className="mt-8 lg:mt-11 pt-8 lg:pt-11 border-t border-gray-100 flex space-x-3">
                        <div className="bg-teal-100 rounded-2xl h-14 w-14 flex items-center ml-0.5 justify-center flex-shrink-0">
                            <SustainabilityIcon height={28} color="#046d3d" /> 
                        </div>
                        
                        <div className="mx-auto bg-blue-50 flex flex-col items-center rounded-3xl w-full p-5 xl:px-9 xl:py-14">
                            <h5 className="andie-section-header">Building a better future with you</h5>
                            <p className="mt-6 andie-description-text leading-tight mx-auto text-center max-w-xl">At Andie our mission is to ensure that connections made through our bidding process benefit transaction partners, our communities, and the planet. Work with us to deliver your environmental, corporate, and social responsibilities.</p>
                            <Link to="/cge/create-a-bid" className="mt-6 andie-dark-button w-32">Create a bid</Link>
                        </div>
                        <div className="flex flex-col justify-end"> 
                            <div className="bg-yellow-100 rounded-2xl h-14 w-14 flex items-center ml-0.5 justify-center flex-shrink-0">
                                <BsEmojiSmile className="h-[20px] text-amber-600" /> 
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center mt-8 xl:mt-10">
                        <div className="border-b-2 w-full border-dashed h-1" />
                        <div className="rounded-full py-1.5 px-5 border-2 text-base text-blue-600 inline-flex flex-shrink-0 border-gray-100 font-productsans-bold">How we are helping</div>
                        <div className="border-b-2 w-full border-dashed h-1" />
                    </div>
                    <div className="space-y-7 divide-y divide-gray-100">
                        <div className="grid md:grid-cols-2 gap-4 xl:gap-5 items-center">
                            <img src={businesssupport} alt="pluck-sustainability" className="h-[500px] object-contain w-auto" />
                            <div className="xl:pr-6">
                                <h6 className="andie-section-header">Supporting the businesses of today and tomorrow.</h6>
                                <p className="mt-6 andie-description-text leading-tight">We love partnering with business of all sizes, that have inspiring stories to tell and can help us create a better tomorrow for the communities and markets that we serve.</p>
                                <div className="mt-6 flex flex-wrap">
                                    {business_types.map((item, index) => (
                                        <div key={index} className="border font-roboto-medium andie-description-text text-gray-500 px-4 mr-2.5 mb-2.5 border-gray-100 rounded-full h-7 inline-flex items-center">
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 gap-4 xl:gap-5 items-center pt-6 xl:pt-8">
                            <img src={sustain} alt="pluck-sustainability" className="rounded-xl object-contain w-auto md:order-2" />
                            <div className="xl:pr-6 md:order-1">
                                <h6 className="andie-section-header">Working with partners like you to champion sustainability.</h6>
                                <div className="mt-6 space-y-3">
                                    {sustainability.map((item, index) => (
                                        <div className="flex items-center space-x-2.5">
                                            <CheckCircleIcon className="h-6 text-blue-600" />
                                            <p className="andie-description-text leading-none">{item}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 gap-4 xl:gap-5 items-center">
                            <img src={fooddrives} alt="pluck-sustainability" className="h-[500px] object-contain w-auto" />
                            <div className="xl:pr-6">
                                <h6 className="andie-section-header">One meal. One smile.</h6>
                                <p className="mt-6 andie-description-text leading-tight">We partner with local charities in our geographical markets to share joy and smiles through extra meals from orders, donated warm meals, food drives, providing technical expertise free-of-cost, and volunteering our time when needed.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 pt-7 border-t border-gray-100">
                        <div>
                            <h5 className="text-base font-productsans-bold text-gray-700">Working with Andie</h5>
                            <p className="andie-section-descriptor mb-0">Together we can work towards create lasting positive impact in our communities.</p>
                        </div>
                        <div className="mt-6 grid md:grid-cols-3 gap-4 xl:gap-5">
                            {clickouts.map((clickout, index) => (
                                <div key={index} className="border rounded-md p-3">
                                    <div className="flex justify-between space-x-3">
                                        <div className="h-9 w-9 flex flex-shrink-0 justify-center items-center rounded-full bg-blue-100">
                                            {clickout.icon}
                                        </div>
                                        <div>
                                            <h6 className="font-roboto-medium text-sm mb-1">{clickout.header}</h6>
                                            <p className="andie-description-text mr-4 leading-tight">{clickout.description}</p>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-2 flex justify-end">
                                        <Link to={clickout.link} className="andie-text-button">{clickout.title}</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <SidebarRight />
            </div>
        </div>
    )
}