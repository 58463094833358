import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";


import { HeaderLeftSide, password_styles, header_styles_mobile, header_styles } from "..";
import dining from "../../../assets/images/categories/dining.png";
import catering from "../../../assets/images/categories/catering.png";
import experiences from "../../../assets/images/categories/experiences.png";
import events from "../../../assets/images/categories/events.png";
import { AccountsAPI } from '../../../apis';
import { toast } from 'react-hot-toast';
import { AiOutlineArrowLeft } from 'react-icons/ai';

function HeaderLoginModal({ loginModalOpened, setLoginModalOpened }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onLogin = async () => {
        setLoading(true);

        let response = await AccountsAPI.LoginUser({
            email: email,
            password: password,
        });

        if (response === 200) {
            window.location.reload();
            // } else if (response === 428) {
            // navigate('/redirect', { state: { email: email } })
        } else if (response === "Retry") {
            setLoading(false);
            setEmail("");
            setPassword("");
            toast.error("Invalid Email or Password. Please Try Again.");
        }
    };

    const footer = [
        {
          title: "Privacy Policy",
          link: "/policies/privacy-policy",
        },
        {
          title: "Terms",
          link: "/policies/terms-of-use",
        },
        {
          title: "Support",
          link: "/support",
        },
        {
          title: "About Andie",
          link: "https://pluckbids.com",
        }
    ];

    const onSignup = () => {
        setLoginModalOpened(false);
        navigate("/signup");
    };

    const onExplore = () => {
        navigate("/");
      };
    
    return (
        <Modal
                blockScroll={false}
            open={loginModalOpened}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
            styles={window.innerWidth < 1024 ? header_styles_mobile : header_styles}
            center
        >
            <div className="flex h-full w-full">
                <div className="hidden md:w-1/2 md:inline-flex flex-col justify-between p-8 h-full bg-blue-600">
                    <div className="flex justify-start">
                        <QuestionMarkCircleIcon className="h-4 text-white" />
                    </div>

                    <div className="mb-20">
                        <h3 className="md:text-xl lg:text-4xl font-productsans-regular text-white leading-0">
                            Great corporate guest experiences start with Andie.
                        </h3>
                        <div className="mt-10 grid grid-cols-4 gap-3 w-4/5">
                            <div>
                                <img
                                    src={dining}
                                    alt="Dining"
                                    className={`object-cover h-10`}
                                />
                                <h6 className="text-gray-200 ml-0.5 font-roboto-regular text-xs">
                                    Dining
                                </h6>
                            </div>
                            <div>
                                <img
                                    src={catering}
                                    alt="Dining"
                                    className={`object-cover h-9`}
                                />
                                <h6 className="text-gray-200 mt-1 font-roboto-regular text-xs">
                                    Catering
                                </h6>
                            </div>
                            <div>
                                <img
                                    src={experiences}
                                    alt="Dining"
                                    className={`object-cover h-10`}
                                />
                                <h6 className="text-gray-200 ml-0.5 font-roboto-regular text-xs">
                                    Experiences
                                </h6>
                            </div>
                            <div>
                                <img
                                    src={events}
                                    alt="Dining"
                                    className={`object-cover h-9`}
                                />
                                <h6 className="text-gray-200 ml-0.5 mt-1 font-roboto-regular text-xs">
                                    Events
                                </h6>
                            </div>
                        </div>
                        <h6 className="text-gray-200 mt-5 font-productsans-medium text-sm">
                            + More
                        </h6>
                    </div>
                    <div className="flex items-center justify-between md:mt-12 xl:mt-28 mb-1">
                        <h4 className="font-productsans-regular text-xl text-white">
                            andie
                        </h4>
                        <p className="text-xs font-sf-regular text-gray-200 mr-2">
                            Private Access
                        </p>
                    </div>
                </div>

                <div className="md:w-1/2 p-4 md:p-8 flex flex-col justify-between">
                    <button
                        className="flex items-center space-x-2 focus:outline-none"
                        onClick={onExplore}
                    >
                        <AiOutlineArrowLeft size={15} className="text-gray-500" />
                        <h6 className="text-gray-500 font-productsans-medium text-sm">
                            Explore
                        </h6>
                    </button>
                    <div className="-mt-14">
                        <h2 className="mb-8 flex justify-center text-xl text-gray-700 font-productsans-medium">
                            Login to continue
                        </h2>
                        <p className="mt-5 flex justify-around gap-y-4 flex-col">
                            <input
                                type="text"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border-2 w-full p-2 font-roboto-regular text-paragraph"
                            />
                            <input
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="border-2 w-full p-2 font-roboto-regular text-paragraph"
                            />
                            <button
                                onClick={() => onLogin()}
                                type="submit"
                                className="andie-dark-button"
                            >
                                {loading ? (
                                    <ThreeDots
                                        height="30"
                                        width="30"
                                        radius="9"
                                        color="#fff"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                ) : (
                                    "Login"
                                )}
                            </button>
                        </p>

                        <div className="mt-5 text-xs font-roboto-regular text-gray-400">
                            By continuing, you agree to Andie's Conditions of Use and
                            Privacy guidelines.
                        </div>
                        <div className="flex items-center my-9">
                            <div className="border-[1px] w-1/3"></div>
                            <div className="uppercase font-roboto-medium text-xs text-gray-600 w-full text-center">
                                {"New to Andie CGE?"}
                            </div>
                            <div className="border-[1px] w-1/3"></div>
                        </div>
                        <button className="andie-outline-button" onClick={onSignup}>
                            Get started
                        </button>
                    </div>
                    <div className="flex justify-center">
                        <div className="flex space-x-5 mb-2">
                            {footer.map((item, index) => (
                                <a
                                    target="_blank"
                                    href={item.link}
                                    rel="noreferrer"
                                    className="text-docoration-none cursor-pointer text-xs font-roboto-medium text-gray-500"
                                >
                                    {item.title}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    );
}

export default HeaderLoginModal;
