import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function RequestAccessCodeSuccess() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col h-screen justify-center items-center">
            <FaCheckCircle className="text-green-600 text-6xl mb-4" />
            <h1 className="text-xl font-productsans-medium text-green-600">Thank you for requesting access to Andie</h1>
            <p className="max-w-xs mx-auto text-center andie-description-text text-gray-600 mt-2">
                A member of our onboarding team is reviewing your request. We'll be in touch shortly!
            </p>
            <p className='mt-8 xl:mt-16 andie-text-button' onClick={() => navigate('/bids')}>Explore latest bids and responses</p>
        </div>
    )
}