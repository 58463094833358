import React from "react";

export default function ExploreRestaurant({ src, text, url }) {
    return (
        <div className="relative w-full">
            <img src={src} alt="Banner" className="w-full object-cover h-[365px] rounded-xl" />
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="bg-black bg-opacity-50 mx-6 max-w-xl rounded-md text-white text-center p-4">
                    <h3 className="mt-5 text-xl md:text-3xl font-productsans-medium">{text}</h3>
                    <p className="mt-2 andie-description-text leading-tight text-white">No matter your size, Andie is your gateway to big-ticket catering and dining opportunities that bring significant traffic and revenue to your business.</p>
                    <a  target="_blank" className="mt-5 mb-6 w-40 andie-dark-button" href={url}>Become a partner</a>
                </div>
            </div>
        </div>
    )
}