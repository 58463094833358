import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BookmarkIcon as BookmarkIconFull, CashIcon, CheckCircleIcon, ClipboardListIcon, CreditCardIcon, InformationCircleIcon, LightBulbIcon, QrcodeIcon, ShieldCheckIcon, StarIcon, UserIcon, ViewListIcon } from '@heroicons/react/solid';
import { BookmarkIcon } from '@heroicons/react/outline';

import { taxi, bus, elevator, stairs, accessible } from '../../assets/images/amenities';
import { BidMap } from '.';
import { CGETypeImage, budgetInference, calculateBudget, moneySignText, pricetoString } from '../common';
import { AccountsAPI, BiddingAPIs } from '../../apis';
import { AnyPackagingIcon, BuffetIcon, ChafingDishIcon, StaffingIcon } from '../../assets/icons';

export default function BidInfoBar({ bid, similarBids }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));
    const navigate = useNavigate();
    const [loadingSave, setLoadingSave] = useState(false);

    // VICTOR PUT THIS IN GLOBALS JS
    const amenityoptions = {
        Elevators: "elevator access",
        Parking: "parking available",
        Sidewalk: "sidewalk access",
        Stairs: "stairs",
        "Ride Share": "accessible by rideshare",
        Transit: "accessible by transit",
        Accessible: "accessibility access",
    }
    // VICTOR PUT THIS IN GLOBALS JS
    const transactionTerms = [
        "All bids, responses, and transactions are protected by the Andie Guarantee. No party is allowed to participate on the platform without prior vetting, and all transactions are monitored to ensure success.",
        "This bid can be cancelled without any penalties, if no response(s) have been accepted.",
        "Changes can be made to this bid up to two hours before the event time. Quantity and item additions or subtractions are subject to approval by the restaurant or caterer. If an item or quantity cannot be reduced during a transaction, a coupon will be provided for the corresponding value.",
        "Once response is accepted, the restaurant is required to provide a final confirmation before the bid can proceed. Payments are processed when the confirmation is received.",
        "Upon receiving the restaurant confirmation, the name and contact information of all parties in the transaction will be shared.",
        "Payments will be prepaid in full before the event date. Payments are released to the restaurant or caterer upon the completion of the bid.",
        "Lateness is not permitted on the platform. Failure to show up or deliver expect menu items within a reasonable time may be subject to appropriate penalties.",
        "Soliciting any parties involved in a transaction outside of the platform is against our Privacy and Terms of Use policies."
    ];

    const handleSaveBid = async (bid_id, save) => {
        if (loadingSave == false) {
            setLoadingSave(true)
            const payload = {
                'bid_id': bid_id
            }
            let tempUser = user
            if (save) {
                await BiddingAPIs.saveBid(payload, user.account_type);
                tempUser.saved_bids?.push(bid_id.toString())
                localStorage.setItem('user_details', JSON.stringify(tempUser))
                setUser(tempUser)
            } else {
                await BiddingAPIs.unsaveBid(payload, user.account_type);
                let savedBidsArr = []
                tempUser.saved_bids?.map((bid) => {
                    if (bid_id != bid) {
                        savedBidsArr.push(bid)
                    }
                })
                tempUser.saved_bids = savedBidsArr
                localStorage.setItem('user_details', JSON.stringify(tempUser))
                setUser(tempUser)
            }
            setLoadingSave(false)
            AccountsAPI.VerifyUserOnLogin();
        }
    }

    function bidExpiryMessage() {
        if (bid.expired || bid.active == false) {
            return (
                `This bid has closed and is no longer accepting responses`
            );
        } else {
            return `This bid is open until ${new Date(bid.expiry_date).toDateString()} at ${moment(bid.expiry_date).format('LT')}`;
        }
    }

    function renderAccess() {
        let str;

        if (bid.accessibility.length > 0) {
            str = "This location has ";
        } else {
            str = "This location has no relevant ammenities.";
        }
        let trueCount = 0;
        Object.keys(bid.accessibility).map(((option, index) => {
            if (bid.accessibility[option] == true) {
                if (amenityoptions[option] == null) {
                    str += option.toLowerCase() + ', '
                } else {
                    str += amenityoptions[option] + ', '
                }
                trueCount++
            }
        }))
        str.trim()
        str = str.slice(0, str.lastIndexOf(',')) + "."
        if (trueCount > 1) {
            str = str.slice(0, str.lastIndexOf(',') + 1) + " and " + str.slice(str.lastIndexOf(',') + 1)
        }
        return (
            <p className="leading-tight andie-description-text"> {str} </p>
        )
    }

    return (
        <>
            <div className="mt-7 md:mt-10 lg:mt-14 border-t pt-8">
                <div className="flex space-x-2.5 mb-4 items-center">
                    <InformationCircleIcon className="h-5 rounded-full border-2" />
                    <h6 className="bid-details-header">About this bid</h6>
                </div>
                <p className="andie-description-text md:w-3/4">{bid.bid_description}</p>
            </div>
            <div className="mt-6">
                <div className="md:flex md:items-center pt-3">
                    <div className="md:w-1/2">
                        <div className="md:w-[90%]">
                            <div className="flex py-3 border-b border-gray-100">
                                <div className="w-2/5">
                                    <h6 className="bid-label">Location</h6>
                                </div>
                                <div className="w-3/5">
                                    <p className="andie-description-text">{bid.locationAdditionalDetails}</p>
                                </div>
                            </div>
                            <div className="flex py-3 border-b border-gray-100">
                                <div className="w-2/5">
                                    <h6 className="bid-label">Amenities</h6>
                                </div>
                                <div className="w-3/5">
                                    {bid.accessibility &&
                                    <div className="flex space-x-2 mb-4">
                                        {Object.keys(bid.accessibility).map(((option) => {
                                            if (bid.accessibility[option] == true) {
                                                let img = null;
                                                if (option == "Parking") {
                                                    img = <img src={taxi} className='p-2' />
                                                } else if (option == "Accessible") {
                                                    img = <img src={accessible} className='p-2' />
                                                } else if (option == "Transit") {
                                                    img = <img src={bus} className='p-2' />
                                                } else if (option == "Elevators") {
                                                    img = <img src={elevator} className='p-2' />
                                                } else if (option == "Stairs") {
                                                    img = <img src={stairs} className='p-2' />
                                                } else {
                                                    img = <p> {option[0]} </p>
                                                }
                                                return (
                                                    <div className="h-10 w-10 rounded-lg bg-purple-200 flex items-center justify-center font-roboto-bold">
                                                        {/* {option[0]} */}
                                                        {img}
                                                    </div>
                                                )
                                            }
                                        }))}
                                    </div>}
                                    {renderAccess()}
                                </div>
                            </div>
                            <div className="flex py-3 border-b border-gray-100">
                                <div className="w-2/5">
                                    <h6 className="bid-label">Arrival time</h6>
                                </div>
                                <div className="w-3/5">
                                    <p className="andie-description-text">Menu items must arrive no later than 30-45 mins before the event time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2 md:pl-3 xl:pl-5">
                        {bid.locationGeometry !== '' ?
                            (
                                <BidMap geo={bid.locationGeometry} />
                            ) : (
                                <div></div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="mt-8 border-t pt-8">
                <div className="rounded-xl pb-4 md:pb-0 bg-blue-100">
                    <div className="md:flex p-3">
                        <div className="flex md:w-3/4">
                            <div className="h-10 w-10 md:h-8 md:w-8 flex-shrink-0 flex items-center justify-center rounded-lg bg-blue-200">
                                <LightBulbIcon className="h-6 text-blue-600" />
                            </div>
                            <div className="ml-4 xl:mr-4">
                                <h6 className="bid-label text-blue-600">Insights</h6>
                                <p className="andie-description-text leading-tight mb-2">This <span className='lowercase font-roboto-bold'>{bid.cge_type} opportunity</span> is a {bid.recurring ? "recurring event" : "one-time event"} with {bid.dietary_preferences.includes("None") ? "no or flexible dietary constraints" : `${(bid.dietary_preferences.filter(pref => pref.preference != "None")).length} important dietary requirements`}. Compared to similar bids with identical specifications, this bid has a {budgetInference(bid.budget.perHead, bid.cge_type).toLowerCase()} budget.</p>
                            </div>
                        </div>
                        <div className="ml-14 mt-1 md:ml-0 md:w-1/4 md:border-l md:border-blue-200 md:pl-5 space-y-1">
                            <div className="flex items-center space-x-2.5">
                                <CheckCircleIcon className="h-4 text-blue-600" />
                                <h6 className="andie-description-text font-roboto-medium">Verified business.</h6>
                            </div>
                            <div className="flex items-center space-x-2.5">
                                <CheckCircleIcon className="h-4 text-blue-600" />
                                <h6 className="andie-description-text font-roboto-medium">{(bid.dietary_preferences.filter(pref => pref.preference != "None")).length} dietary requirements.</h6>
                            </div>
                            <div className="flex items-center space-x-2.5">
                                <CheckCircleIcon className="h-4 text-blue-600" />
                                <h6 className="andie-description-text font-roboto-medium">{budgetInference(bid.budget.perHead, bid.cge_type)} budget.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 border-t border-b py-8">
                <div className="flex space-x-2.5 mb-4 items-center">
                    <ViewListIcon className="h-5" />
                    <h6 className="bid-details-header">Response Guidelines</h6>
                </div>
                <div className="lg:flex">
                    <div className="lg:w-3/4 xl:w-2/3">
                        <div className="grid grid-cols-2 gap-2 md:gap-4 py-4 xl:py-6">
                            <div>
                                <h6 className="bid-label leading-none mb-4 md:mb-0">Restaurant level</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">This bid is open to restaurants with the following levels:</p>
                                <div className="inline-flex border-2 border-gray-600 rounded-full h-6 px-3 text-[13px] font-roboto-medium space-x-1 text-gray-600 items-center">
                                    <StarIcon className="h-4 -mt-0.5 text-gray-600" />
                                    {bid.restaurant_rating}
                                </div>
                            </div>
                            <div>
                                <h6 className="bid-label leading-none mb-4 md:mb-0">Cuisine</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">This bid is open to these following cuisines:</p>
                                <div className="flex flex-wrap pr-4">
                                    {bid.restaurant_type.map((restaurant, index) => (
                                        <div key={index} className="bg-blue-100 h-6 px-3.5 mb-2 mr-1.5 rounded-full flex justify-between items-center">
                                            <p className="text-[13px] font-roboto-medium text-blue-500">{restaurant}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2 md:gap-4 py-4 xl:py-6 border-t border-gray-100">
                            <div>
                                <h6 className="bid-label leading-none mb-4 md:mb-0">Menu options</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">Bid responses must include the following options:</p>
                                <p className="mt-1.5 andie-description-text">{bid.menu_options.join(', ')}.</p>
                            </div>
                            <div>
                                <h6 className="bid-label leading-none mb-4 md:mb-0">Packaging & presentation</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">Items must be packed and presented as following:</p>
                                <div className="grid">
                                    {bid.packaging_type.map((packagingType, index) => {
                                        return (
                                            <div key={index} className="w-full space-x-1.5 flex items-center">
                                                {packagingType === "Any" && <AnyPackagingIcon height={26} />}
                                                {packagingType === "Individually" && <UserIcon className="h-[22px] text-black" />}
                                                {packagingType === "Buffet / Food Station" && <BuffetIcon height={22} />}
                                                <p className="andie-description-text mb-0 font-roboto-medium text-gray-500">{packagingType}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2 md:gap-4 py-4 xl:py-6 border-t border-gray-100">
                            <div className="col-span-2 md:col-span-1 mb-4 md:mb-0">
                                <h6 className="bid-label leading-none mb-4 md:mb-0">Dietary restrictions</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">Bid responses must fulfill the following conditions:</p>
                                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-2 xl:w-3/4">
                                    {bid.dietary_preferences.map((preference, index) => (
                                        <div key={index} className="w-full flex items-center">
                                            <div className="h-2 w-2 flex-shrink-0 bg-gray-200 rounded-full mr-2" />
                                            <p className="andie-description-text font-roboto-medium text-gray-500">{preference.preference}</p>
                                        </div>
                                    ))}
                                </div>
                                {bid.dietary_notes &&
                                    <p className="mt-2 andie-description-text">{bid.dietary_notes}</p>
                                }
                            </div>
                            <div>
                                <h6 className="bid-label leading-none mb-4 md:mb-0">{bid.cge_type.includes("Dining") ? "Serving" : "Delivery"} Time</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">{bid.cge_type.includes("Dining") ? "Menu items must be served by:" : "This order must be delivered by:"}</p>
                                <div className="andie-description-text font-roboto-medium text-gray-500">
                                    {moment(bid.delivery_time).format('hh:mm A')}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2 md:gap-4 py-4 xl:py-6 border-t border-gray-100">
                            <div>
                                <h6 className="bid-label leading-none mb-4 md:mb-0">Chafing Dishes</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">This bid requires the following:</p>

                                <div className="mt-1 md:flex md:space-x-1.5 space-y-1.5 md:items-center">
                                    <ChafingDishIcon height={26} color={!bid.requires_chafing ? "#e5e7eb" : "#111827"} />
                                    <p className="andie-description-text leading-tight">{bid.requires_chafing ? "Requires chafing dishes" : "Does not require chafing dishes"}.</p>
                                </div>
                            </div>
                            <div>
                                <h6 className="bid-label leading-none mb-4 md:mb-0">Staffing</h6>
                                <p className="andie-section-descriptor hidden md:inline-flex leading-none">Bidders must provide the following:</p>
                                <div className="md:flex md:space-x-1.5 space-y-1.5 md:items-center">
                                    <StaffingIcon height={25} color={(bid.requires_staffing || bid.cge_type.includes("Dining")) ? "#111827" : "#e5e7eb"} />
                                    {bid.cge_type.includes("Dining") ? 
                                        <p className="andie-description-text leading-tight">Dedicated server / host.</p>
                                        :
                                        <p className="andie-description-text leading-tight">{bid.requires_staffing ? "Requires servers / host.": "Does not require servers / host."}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/4 xl:w-1/3 lg:pl-3 xl:pl-5">
                        <div className="p-3 border-2 rounded border-gray-100 h-full">
                            <h6 className="bid-label text-yellow-600 mb-0">Additional Notes</h6>
                            {bid.additional_notes ?
                                <div className="flex mt-4">
                                    <p className="andie-description-text">{bid.additional_notes}</p>
                                </div>
                                :
                                <div className="flex h-full py-5 md:py-0 items-center justify-center">
                                    <p className="andie-description-text leading-tight text-gray-400 text-center">{bid.additional_notes ? bid.additional_notes : "There are no additional notes that have been provided for this bid."}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-8">
                <div className="flex space-x-2.5 mb-4 items-center">
                    <CreditCardIcon className="h-6 -mt-0.5" />
                    <h6 className="bid-details-header">Financial Details</h6>
                </div>
                <div className="rounded-xl bg-green-50">
                    <div className="md:grid md:grid-cols-2 xl:grid-cols-4 md:gap-4 md:divide-x divide-green-100 border-b border-green-100">
                        <div className="p-3 md:p-4">
                            <h1 className="font-productsans-black text-green-800 text-3xl">
                                {user !== null ?
                                    <>
                                        ${pricetoString(calculateBudget(bid.budget.perHead, 1))} <small className="text-sm">{bid.currency}</small>
                                    </>
                                    :
                                    <>
                                        {moneySignText(calculateBudget(bid.budget.perHead, 1))} <small className="text-sm">{bid.currency}</small>
                                    </>
                                }
                            </h1>
                            <h6 className="-mt-0.5 text-xs font-roboto-medium text-gray-500">per guest</h6>
                        </div>
                        <div className="p-3 md:p-4">
                            <h6 className="bid-label text-green-500">Payment terms</h6>
                            <p className="andie-description-text text-gray-500">{bid.payment_terms}</p>
                        </div>
                        <div className="p-3 md:p-4">
                            <h6 className="bid-label text-green-500">Tip &amp; Gratuity</h6>
                            <p className="andie-description-text text-gray-500">{bid["budget"].tipAmount ? bid["budget"].tipAmount * 100 + "%" : "No tip is included in this bid"}</p>
                        </div>
                        <div className="p-3 md:p-4">
                            <h6 className="bid-label text-green-500">Cancellation</h6>
                            <p className="andie-description-text text-gray-500">Free until {moment(bid.event_date_time).subtract(2, 'd').format('ll')}.</p>
                            <a href="/policies/andie-guarantee" className="text-xs h-5 andie-text-button">Learn more</a>
                        </div>

                    </div>
                    <div className="md:flex md:items-center p-3 md:p-4 md:justify-between">
                        <div className="flex space-x-1">
                            <h6 className="andie-description-text font-roboto-bold underline text-green-700">Subtotal:</h6>
                            {user !== null ?
                                <p className="andie-description-text font-roboto-bold text-gray-500">${pricetoString(calculateBudget(bid.budget.total, bid.restaurant_coefficient))} {bid.currency}</p>
                                :
                                <p className="andie-description-text font-roboto-bold text-gray-500">{moneySignText(calculateBudget(bid.budget.total, bid.restaurant_coefficient))} {bid.currency}</p>
                            }
                        </div>
                        <div className="mt-1.5 md:mt-0 flex md:items-center space-x-1">
                            <InformationCircleIcon className="text-gray-600 h-3.5 flex-shrink-0" />
                            <p className="andie-description-text text-gray-500 leading-tight w-3/4 md:w-full">This bid is subject to applicable taxes and service fees.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-3 flex items-center">
                    <ShieldCheckIcon className="h-4 text-gray-800" />
                    <p className="ml-1 text-xs font-productsans-medium text-gray-600">All transactions are backed by the Andie Guarantee.</p>
                </div>
            </div>
            <div className="mt-8 py-8 border-t">
                <div className="flex space-x-2.5 mb-4 items-center">
                    <ClipboardListIcon className="h-6" />
                    <h6 className="bid-details-header">Terms and conditions</h6>
                </div>
                <div className="mt-3 grid lg:grid-cols-2 gap-6">
                    <div className="space-y-4">
                        {transactionTerms.slice(0, 4).map((term, index) => (
                            <div className="flex">
                                <div className="andie-description-text text-gray-500 mr-2">{index + 1}. </div>
                                <p className="andie-description-text">{term}</p>
                            </div>
                        ))}
                    </div>
                    <div className="space-y-4">
                        {transactionTerms.slice(4, 8).map((term, index) => (
                            <div className="flex">
                                <div className="andie-description-text text-gray-500 mr-2">{index + 5}. </div>
                                <p className="andie-description-text">{term}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 rounded h-full space-y-6 p-4">
                <div>
                    <h6 className="bid-details-header">Transaction code</h6>
                    <p className="font-productsans-medium text-2xl text-blue-600">{bid.transaction_code}</p>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div>
                        <h6 className="bid-label mb-0">How to use this code?</h6>
                        <p className="andie-description-text leading-snug">For questions and inquiries about this bid or its submitted responses, please include the transaction code.</p>
                    </div>

                    <div className="flex md:justify-end">
                        <div className="bg-gray-200 p-2 rounded">
                            <h6 className="bid-details-header">Expiry date</h6>
                            <   p className="andie-description-text">{bidExpiryMessage()}.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-9">
                {similarBids.length > 1 &&
                    <div  className="border-t pt-9 w-full ">
                        <div className="flex space-x-2.5 mb-4 items-center">
                            <CashIcon className="h-6 -mt-0.5" />
                            <h6 className="bid-details-header">Similar bids</h6>
                        </div>
                        <div className="mt-5 flex overflow-x-auto scrollbar-hide w-full gap-4">
                            {similarBids.map((simBid, index) => {
                                return (
                                    bid.transaction_code == simBid.transaction_code ?
                                        null
                                        :
                                        <div onClick={() => navigate('/bid/' + simBid.transaction_code)} key={index} className="hover:bg-gray-50 shrink-0 w-60 h-36 flex flex-col justify-between border-2 p-4 border-gray-100 rounded-xl cursor-pointer select-none">
                                            <div className="flex items-center space-x-2">
                                                <div className="bg-blue-50 h-10 w-10 flex items-center justify-center flex-shrink-0 rounded-md" >
                                                    <CGETypeImage type={simBid.cge_type} page={"SimilarBids"} />
                                                </div>
                                                <div>
                                                    <h5 className="font-productsans-medium text-sm leading-tight line-clamp-1 text-gray-600">{simBid.bid_title}</h5>
                                                    <h6 className="text-xs font-roboto-medium text-blue-600">{simBid.event_type}</h6>
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-end">
                                                <div>
                                                    {user != null ?
                                                        <h6 className="text-sm font-productsans-bold text-green-600">${pricetoString(calculateBudget(simBid.budget.total, simBid.restaurant_coefficient))} <small>{simBid.currency}</small></h6>
                                                        :
                                                        <h6 className="text-sm font-productsans-bold text-green-600">${moneySignText(simBid.budget.total)}</h6>
                                                    }
                                                    <p className="text-xs font-roboto-medium text-gray-400">{moment(simBid.event_date).format("MMMM Do, YYYY")}</p>
                                                </div>
                                                <p className="text-xs font-roboto-medium text-gray-400">{simBid.cge_type}</p>
                                            </div>
                                            {user ?
                                                <div className="mt-2 flex flex-row justify-between">
                                                    <div className="cursor-pointer text-xs underline text-blue-600 font-bold text-right">View bid</div>
                                                    {user.saved_bids?.includes(simBid.id.toString()) ?
                                                        <BookmarkIconFull onClick={() => handleSaveBid(simBid.id, false)} className="cursor-pointer h-4 text-blue-600" />
                                                        : <BookmarkIcon onClick={() => handleSaveBid(simBid.id, true)} className="cursor-pointer h-4 text-blue-600" />}
                                                </div> :
                                                <></>
                                            }
                                        </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            <p className="mt-9 mb-5 md:mb-12 andie-description-text">Posted on {new Date(bid.date_created).toDateString()} at {moment(bid.date_created).format('LT')}</p>
        </>
    )
}