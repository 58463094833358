import { useState, useEffect, useRef } from "react";
import { Header } from "../../components/navigation";
import { DocumentDownloadIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import moment from "moment";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router";
import { ClipboardListIcon, FlagIcon } from "@heroicons/react/outline";

import { BiddingAPIs } from "../../apis";
import {CGETypeImage, LoadingIcon} from "../../components/common";
import { ChatRender, ConversationTab } from '../../components/messages';
import { modalSize } from "../../components/common/global";



export default function Messages() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [loading, setLoading] = useState(true);
    // const [selectedBid, setSelectedBid] = useState(0)
    // const [selectedResponse, setSelectedResponse] = useState(0)
    const [loadingBids, setLoadingBids] = useState(false);
    const [myBids, setMyBids] = useState([]);
    const [selectedConv, setSelectedConv] = useState(0);
    const [viewBid, setViewBid] = useState(false);
    const [showDrawer, setshowDrawer] = useState(false);
    const botElm = useRef(null)
    const [drawerloading, setdrawerLoading] = useState();
    const [convTotal, setConvTotal] = useState(0);
    const [emtpyConv, setEmptyConv] = useState([])
    const [conversations, setConversations] = useState([])
    const [msgModal, setMsgModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [newMsg, setNewMsg] = useState({});
    const [mobileMessage, setMobileMessage] = useState(false)
    const [unreadMsgs, setUnreadMsgs] = useState(0);
    const [reloadFlag, setReloadFlag] = useState(false);

    const navigate = useNavigate()



    // useEffect(() => {
    //     botElm?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    // }, [selectedConv])

    useEffect(() => {
        getConversations()
        getUnreadMessages()
    }, [])

    useEffect(() => {
        let total = 0
        conversations.forEach((conv) => {
            if (conv.messages.length > 0) {
                total++
            }
        })
        setConvTotal(total)
    }, [conversations])

    async function getConversations() {
        const res = await BiddingAPIs.fetchAllMessages(user.email)
        setConversations([...res])
        setLoading(false)
    }

    async function getUnreadMessages() {
        const res = await BiddingAPIs.getUnreadMessages()
        setUnreadMsgs(res.unread)
      }

    
    function handleNewMsg(resp) {
        resp.conversation.messages.push(JSON.stringify({
            to: user.email,
            timestamp: moment.now(),
            message: "Begin by sending your first message!"
        }))
        let index = 0
        let bids = myBids.map((bid, bidIndex) => {
            if (bid.title = resp.bid_title) {
                bid.responses.push(resp)
                index = bidIndex
            }
            return bid
        })
        setMyBids([...bids])
    }


    function showFullBid(){
        navigate('/bid/'+conversations[selectedConv].bid_code);
    }

    async function readMsg(convID) {
        const res = await BiddingAPIs.readMessages(convID);
        getUnreadMessages();
    }

    function handleConvTab(conv, index) {
        setSelectedConv(index);
        setNewMsg({});
        if (JSON.parse(conv.messages.at(-1)).read == false && JSON.parse(conv.messages.at(-1)).to == user.email) {
            readMsg(conv.id);
        }
    }

    async function markAllRead() {
        setUnreadMsgs(0)
        let temp = conversations.map((conv) => {
            if (conv.messages.length > 0) {
                // console.log(conv.messages)
                let tempMsg = JSON.parse(conv.messages.at(-1))
                tempMsg.read = true
                conv.messages[conv.messages.length - 1] = JSON.stringify(tempMsg)
                // return JSON.stringify(conv)
                console.log(conv)
                return conv
            } else {
                return conv
            }
        })
        setConversations([...temp])
        setReloadFlag(!reloadFlag)
        const res = await BiddingAPIs.readAllMessages(conversations.map((conv) => conv.id));
    }
    
    function renderMessageSelect() {
        return(
            <div className="w-full md:w-[35%] pt-5 border-gray-100 border-r">
                <div className="flex justify-between items-center px-6">
                    <h5 className="andie-sidebar-title">{conversations.length} Conversations</h5>
            
                    <div className="text-xs font-productsans-bold text-blue-600 cursor-pointer" onClick={() => markAllRead()}>
                        Mark all as read
                    </div>
                </div>
                <ul className="h-full overflow-y-scroll scrollbar-hide pb-40"> 
                      
                    {conversations.map((conv, index) => {
                        if (conv.messages.length > 0) {
                            return (
                                <li
                                    key={conv.id}
                                    className="m-3 h-28 pb-3 border-b"
                                >
                                    <div
                                        key={conv.id}
                                        className={`h-full flex flex-col justify-between cursor-pointer p-3 ${selectedConv == index && "bg-blue-50 rounded-xl"}`}
                                        onClick={() => { setSelectedConv(index); setNewMsg({}); setMobileMessage(true); readMsg(conv.id);}}
                                    >
                                        <ConversationTab conv={conv} selectedConv={selectedConv} index={index} reloadFlag={reloadFlag} />
                                    </div>
                                </li>
                            )
                        }
                    })}
                    <p className="mt-8 text-gray-400 text-opacity-70 text-center font-roboto-regular text-paragraph">{convTotal > 0 ? 'This is the end of your conversations' : "There are no new conversations"}.</p>
                </ul>
            </div>
        )
    }

    function renderMessages() {
        return (
        <>
            {convTotal <= 0 ?
            <div className="flex flex-col items-center justify-center h-full w-full">
                <DocumentDownloadIcon className="h-20 text-blue-600" />
                <p className="mt-3 tex-center text-green-600 text-xs font-roboto-medium">No open messages</p>
                <div className="flex space-x-4 justify-between mt-5 mb-32">
                    <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                    <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                    <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                </div>
            </div>
            :
            <div className="flex flex-col justify-between w-full md:w-[65%]">
                <div className="w-full py-5 pr-5 border-b border-gray-100">
                    <div className="flex justify-between">
                        <div className="w-5/6 flex space-x-3">
                            <ArrowLeftIcon className="translate-y-2.5 h-7 text-gray-500 block md:hidden hover:text-gray-700" onClick={() => setMobileMessage(false)}/>
                            <div className="bg-blue-50 h-12 w-12 flex items-center justify-center flex-shrink-0 rounded-md">
                                <CGETypeImage type={conversations[selectedConv]?.cge_type} page={"Messages"}/>
                            </div>
                            <div className="w-[70%] mt-0.5">
                                <h5 className="text-base cursor-pointer leading-tight line-clamp-1 font-productsans-bold text-gray-800">
                                    {conversations[selectedConv]?.bid_title}
                                </h5>
                                <button className="text-xs andie-text-button h-5 pb-2" onClick={() => setshowDrawer(true)}>
                                    View response
                                </button>
                            </div>
                        </div>
                        <div className="-mt-3 flex items-center space-x-2.5">
                            <div className="andie-circle-button h-8 w-8">
                                <ClipboardListIcon className="text-blue-600 h-4" onClick={() => { showFullBid() }}/> 
                            </div>
                            <div className="andie-circle-button h-8 w-8" onClick={() => setReportModal(true)}>
                                <FlagIcon className="text-blue-600 h-4" />
                            </div>
                            <div className={`bg-gray-200 h-2 w-2 flex-shrink-0 rounded-full ${"bg-blue-600"}`} />
                        </div>
                    </div>
                </div>
                    {conversations.length > 0 ?
                        <ChatRender page="messages" setConversations={(e) => setConversations(e)} conversations={conversations}  selectedConv={selectedConv} getUnreadMessages={() => getUnreadMessages()} />
                        :
                        <></>
                    }
            </div>
        }
        </>
        )
    }

    return (
        <div className="h-screen">
            {/* <Header page={"messages"} /> */}
            <Modal
                blockScroll={false}
                open={reportModal}
                onClose={() => setReportModal(false)}
                animationDuration={600}
                showCloseIcon={false}
                styles={modalSize()}
                center
            >
                <div className="h-full">
                    <form action="#" class="space-y-8">
                        <div class="">
                            <div>
                                <h2 class="font-productsans-medium text-gray-700 text-xl px-4 py-4 border-b-2 bg-white border-gray-300">Send your feedback to Andie</h2>
                            </div>
                            <div class="mt-3 mx-3">
                                <label for="message" class="block mb-2 text-gray-600 line-clamp-2 font-medium text-sm mr-5 font-sf-regular">Describe Feedback</label>
                                <textarea id="message" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Tell us any improvements we can help you with"></textarea>
                                <label for="message" class="block my-2 text-gray-600 line-clamp-2 mr-5 font-sf-regular text-xs">Please do not include any sensitive information</label>
                            </div>
                            <div className="absolute bottom-0 rounded-b-xl w-full">
                                <p for="message" class="block my-2 text-gray-600 mr-5 font-sf-regular text-xs p-4">When sending feedback, account information may be sent to Andie. We will use this information to fix the problem in question, or contact you further in regards to clarification, updates, and further information. Privacy will always be respected and we will never ask for sensitive information.</p>
                                <div class="bg-white border-gray-300 border-t-2 rounded-b-xl p-4">
                                    <button class="bg-gray-200 rounded-md text-gray-500 hover:bg-gray-300 hover:text-gray-800 font-sf-regular text-sm py-3 px-5 border border-gray-400" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <div className="flex h-full">
                {loading ?
                    <div role="status" className="w-full flex items-center justify-center" >
                        <LoadingIcon />
                    </div>
                    :
                    <>
                        <div className="hidden w-[17%] md:flex flex-col pt-6 px-6">

                            <div className="flex items-center space-x-2">
                                <h6 className="text-base font-productsans-medium text-gray-600">Messages</h6>
                                <div className="h-4 w-4 flex items-center text-white justify-center rounded-full font-productsans-medium text-xs bg-red-600">
                                    {unreadMsgs}
                                </div>
                            </div>
                            <h6 className="mt-3 text-xs font-roboto-regular text-gray-500">To start a new message, head over to <span> <button className="text-blue-600 underline" onClick={() => navigate('/activity')}> activity </button> </span>, select a bid and response, and click the messaging icon!</h6>

                        </div>
                        <div className="w-[63%] hidden md:block border-l border-r border-gray-100">
                            <div className="flex w-full h-[calc(100%_-_40px)]">
                                {renderMessageSelect()}
                                {renderMessages()}
                            </div>
                        </div>
                        <div className="hidden md:block w-[20%]" />

                        {/* Mobile Setup */}
                        <div className="w-full block md:hidden border-l border-r border-gray-100">
                            <div className="flex w-full h-[calc(100%_-_40px)]">
                                {mobileMessage?
                                    <>{renderMessages()}</>
                                :
                                    <>{renderMessageSelect()}</>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}