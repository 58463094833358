import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { PDFViewer, View, Text, Page, Document, Image, Font } from '@react-pdf/renderer';
import _ from 'lodash';
import AndieLogo from '../../assets/images/pluck-logo.png'
import productSansRegular from "../../assets/fonts/ProductSans-Regular.ttf"
import productSansMedium from "../../assets/fonts/ProductSans-Medium.ttf"
import productSansBold from "../../assets/fonts/ProductSans-Bold.ttf"
import robotoRegular from "../../assets/fonts/Roboto-Regular.ttf"

import { pricetoString, pad, getTransactionAmounts, PopoutModal, dietaryLabels } from "../common";
import moment from "moment";

Font.register({ family: "Product Sans Regular", src: productSansRegular })
Font.register({ family: "Product Sans Medium", src: productSansMedium })
Font.register({ family: "Product Sans Bold", src: productSansBold })
Font.register({ family: "Roboto Regular", src: robotoRegular })


const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "40%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
    page: {
        flexDirection: 'col',
        backgroundColor: '#FFF',
        paddingHorizontal: 25
    },
    text: {
        marginTop: 3,
        fontSize: 10,
        color: "#9ca3af",
        fontFamily: "Product Sans Regular"
    },
    description: {
        marginTop: 4,
        fontSize: 10,
        color: "#9ca3af",
        fontFamily: "Roboto Regular"
    },
    subheading: {
        color: "#374151",
        fontSize: 9,
        marginBottom: 5
    },
    itemTitle: {
        color: "#374151",
        fontSize: 10,
        fontFamily: "Product Sans Medium"
    },
    footer: {
        // position: 'absolute',
        // bottom: 10,
        // right: 0,
        fontSize: 12,
        textAlign: 'center',
        color: 'grey',
    },
    imageContainer: {
        width: "35%",
        height: "auto",
        marginRight: 10,
    },
    image: {
        objectFit: "cover",
    },
};


export default function BidResponseReceipt({ response, account_type, itemsOnly, account, representative, issueDate, admin }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isTransactionView, setTransactionView] = useState(false);
    const location = useLocation();

    const transAmounts = getTransactionAmounts(response.items, response.bid?.budget.tipAmount, response.delivery_fee, response.business_delivers, response.bid?.cge_service_fee, response.business_service_fee, account_type, response.pricing, response.total_price)
    const subtotal = transAmounts.subtotal
    const gratuity = transAmounts.tip
    const service_fee = transAmounts.serviceFee
    const delivery_fee = transAmounts.deliveryFee
    const tax = transAmounts.tax
    const total = transAmounts.total

    const showDeliveryFee = !((account_type === 2) && (!response.business_delivers)) && (response.bid?.cge_type == "Catering" || response.bid?.cge_type == "Catering Plus")

    useEffect(() => {
        // Function to check if the URL contains "/view/transaction/"
        const checkTransactionView = () => {
            const containsTransactionView = location.pathname.includes('/view/transaction/');
            setTransactionView(containsTransactionView);
        };

        // Call the function immediately to set the initial state based on the current URL
        checkTransactionView();
    }, [location]);

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }


    const eventDetails = [
        { title: "Location", data: response.bid?.location },
        { title: "Date", data: new Date(response.bid?.event_date_time).toDateString() },
        { title: "Type", data: response.bid?.cge_type }
    ];

    // const eventDetails = [
    //     { title: "Location", data: "325 Front Street W" },
    //     { title: "Date", data: "Apr 17, 2024" },
    //     { title: "Type", data: response.bid?.cge_type }
    // ];

    const payment = [
        { title: "Payment Method", data: "Digital" },
        // { title: "Payment Due Date", data: "Apr 17, 2024" }
        { title: "Payment Due Date", data: new Date(response.bid?.event_date_time).toDateString() }
    ];

    const summary = showDeliveryFee ? [
        { title: "Subtotal", data: subtotal },
        { title: "Service Fee", data: service_fee },
        { title: "Delivery Fee", data: delivery_fee },
        { title: "Tax", data: tax },
        { title: "Gratuity", data: gratuity },
    ] : [
        { title: "Subtotal", data: subtotal },
        { title: "Service Fee", data: service_fee },
        { title: "Tax", data: tax },
        { title: "Gratuity", data: gratuity },
    ]

    const rows = 13 - summary.length - response.items.length;

    // Create Document Component
    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <View style={{ marginTop: 30, marginBottom: 15, flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 10 }}>
                    <View style={{ flexDirection: "column", width: "60%" }}>
                        <View style={styles.imageContainer}>
                            <Image
                                style={styles.image}
                                // src={AndieLogo}
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_nV4lLl1QQKO4zzQ_WTDaxS9owK5AuCdyHVP9FxAy&s"
                            />
                        </View>
                        <View>
                            {/* <Text style={styles.text}>Andie Technologies Inc.</Text> */}
                            <Text style={styles.text}>Andie Connected Technologies Inc.</Text>
                            <Text style={styles.text}>600 - 20 Richmond Street East, Toronto ON M5C 2R9</Text>
                            <Text style={styles.text}>GST/HST # 742233489RT0001</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: 16, color: "#2563eb", fontWeight: "extrabold" }}>{itemsOnly ? "BR" : account_type === 2 ? "PO" : "Invoice"} #{pad(response.id, 5)}</Text>
                        <Text style={{ marginTop: 5, fontSize: 10, color: "#4b5563" }}><Text style={{ color: "#374151" }}>Issue Date:</Text> {moment(issueDate).format("ddd MMMM Do YYYY")}</Text>
                        {itemsOnly == false &&
                            <View style={{ marginTop: 4, alignItems: "center", justifyContent: "center", width: 50, height: 15, border: 0, borderRadius: 2, backgroundColor: "#2563eb" }}>
                                <Text style={[styles.subheading, { color: "#FFF", marginBottom: 0 }]}>{response.accepted == true ? "Accepted" : response.rejected == true ? "Rejected" : "Pending"}</Text>
                            </View>
                        }
                        {/* <View style={{ marginTop: 10, backgroundColor: "#009473", alignItems: "center", justifyContent: "center", width: 50, height: 15, border: 0, borderRadius: 2 }}>
                            <Text style={[styles.subheading, { color: "#FFF", marginBottom: 0 }]}>Paid</Text>
                        </View> */}
                        <View style={{ marginTop: 10, backgroundColor: "#fde68a", alignItems: "center", justifyContent: "center", width: 50, height: 15, border: 0, borderRadius: 2 }}>
                            <Text style={[styles.subheading, { color: "#000", marginBottom: 0 }]}>Pending</Text>
                        </View>
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between", borderTop: "1px solid #F3F4F6", borderBottom: "1px solid #F3F4F6", marginTop: 15 }}>
                    {itemsOnly ?
                        null
                        :
                        account_type === 2 ?
                            <View style={{ minWidth: "33%", maxWidth: "33%", paddingVertical: 10, paddingHorizontal: 10, borderRight: "1px solid #F3F4F6", borderLeft: "1px solid #F3F4F6" }}>
                                {/* <Text style={styles.subheading}>Bill to:</Text> */}
                                {/* <Text style={styles.text}>Andie Hospitality</Text> */}
                                <Text style={styles.subheading}>Restaurant/Caterer:</Text>
                                {/* <Text style={styles.text}>{account.business_name}</Text>
                                <Text style={styles.text}>{account.address_details}</Text>
                                <Text style={styles.text}>{account.email}</Text> */}
                                <Text style={styles.text}>Meza Catering</Text>
                            </View>
                            :
                            <View style={{ width: "33%", paddingVertical: 10, paddingHorizontal: 10, borderRight: "1px solid #F3F4F6", borderLeft: "1px solid #F3F4F6" }}>
                                <Text style={styles.subheading}>Bill To:</Text>
                                {/* <Text style={styles.text}>{response.bid?.organization}</Text>
                                <Text style={styles.text}>{response.bid?.contact_first_name} {response.bid?.contact_last_name}</Text>
                                <Text style={styles.text}>{response.bid?.contact_email}</Text> */}

                                <Text style={styles.text}>Forgestone Capital</Text>
                                <Text style={styles.text}>Kathy Pelley</Text>
                                <Text style={styles.text}>k.pelley@forgestonecapital.com</Text>

                                {/* <Text style={styles.text}>OneEleven</Text>
                                <Text style={styles.text}>Vanessa Ebiaru</Text>
                                <Text style={styles.text}>info@oneeleven.com</Text> */}

                                {/* <Text style={styles.text}>FutureFit AI</Text>
                                <Text style={styles.text}>Kristina Pavlicek</Text>
                                <Text style={styles.text}>kristina.pavlicek@futurefit.ai</Text> */}
                            </View>
                    }
                    <View style={{ width: "33%", paddingVertical: 10, paddingHorizontal: 10, borderRight: "1px solid #F3F4F6" }}>
                        <Text style={styles.subheading}>Account Manager:</Text>
                        <Text style={styles.text}>{representative?.first_name} {representative?.last_name}</Text>
                        <Text style={styles.text}>{representative?.role}, {representative?.department}</Text>
                        <Text style={styles.text}>{representative?.role}</Text>
                        <Text style={styles.text}>{representative?.phone && formatPhoneNumber(representative?.phone)}</Text>
                        <Text style={styles.text}>{representative?.email}</Text>
                        {/* <Text style={styles.text}>Baba Ajayi</Text>
                        <Text style={styles.text}>(647) 783-9571</Text>
                        <Text style={styles.text}>baba.aj@andie.work</Text> */}
                    </View>
                    <View style={{ width: "33%", padding: 10, borderRight: "1px solid #F3F4F6" }}>
                        <Text style={styles.subheading}>Event Details</Text>
                        {eventDetails.map((detail, index) => (
                            <Text style={styles.text} id={index}><Text style={{ color: "#6b7280" }}>{detail.title}:</Text> {detail.data} </Text>
                        ))}
                    </View>
                </View>
                {itemsOnly ?
                    null
                    :
                    <View style={{ flexDirection: "row", borderRight: "1px solid #F3F4F6", borderBottom: "1px solid #F3F4F6" }}>
                        {payment.map((detail, index) => {
                            return (
                                <View style={{ width: index == 0 ? "33%" : null, paddingVertical: 10, paddingHorizontal: 10, borderLeft: "1px solid #F3F4F6" }}>
                                    <Text style={styles.text} id={index}><Text style={{ color: "#6b7280" }}>{detail.title}:</Text> {detail.data} </Text>
                                </View>
                            )
                        })}
                    </View>
                }
                {/* <View style={{ flexDirection: "row", justifyContent: "space-between", borderTop: "1px solid #F3F4F6", borderBottom: "1px solid #F3F4F6", marginTop: 15 }}>
                    {itemsOnly ?
                        null
                        :
                        
                    }
                </View> */}
                <View style={{ marginTop: 5 }}>
                    <View style={{ flexDirection: "row", height: 32, backgroundColor: "#dbeafe", borderRadius: 2, paddingHorizontal: 10, alignItems: "center" }}>
                        <View style={{ width: "40%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Item</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Category</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0, textAlign: 'center' }]}>Serves</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Unit Price</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0, textAlign: 'center' }]}>Quantity</Text>
                        </View>
                        <View style={{ width: "14%", textAlign: "right" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Subtotal</Text>
                        </View>
                    </View>
                    {response.items.map((item, index) => (
                        <View style={{ minHeight: 32, flexDirection: "row", borderBottom: "1px dashed #e5e7eb", paddingVertical: 10, paddingHorizontal: 10 }} wrap={false}>
                            {/* {console.log(item)} */}
                            <View style={{ width: "40%" }} id={index}>
                                <Text style={[styles.itemTitle, { marginTop: 0, maxWidth: "80%" }]}>{item.name}</Text>
                                {item.description?.trim() == "" ?
                                    null
                                    :
                                    <Text style={[styles.description, { maxWidth: "90%" }]}>{item.description}</Text>
                                }
                                <Text style={[styles.text, { maxWidth: "80%", fontFamily: "Product Sans Bold" }]}>
                                    {item.dietary_option?.map((option, index) => {
                                        let str = ""
                                        if (index == item?.dietary_option.length - 1) {
                                            if (dietaryLabels[option]) {
                                                str += dietaryLabels[option]
                                            }
                                        } else {
                                            if (dietaryLabels[option]) {
                                                str += dietaryLabels[option] + ", "
                                            }

                                        }
                                        return str
                                    })}
                                </Text>
                            </View>
                            <View style={{ width: "14%" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>{item.menuoption}</Text>
                            </View>
                            <View style={{ width: "14%", textAlign: 'center' }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>{item.serves}</Text>
                            </View>
                            <View style={{ width: "14%" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>${pricetoString(item.price)}</Text>
                            </View>
                            <View style={{ width: "14%" }}>
                                <Text style={[styles.text, { marginTop: 0, textAlign: 'center' }]}>{item.quantity}</Text>
                            </View>
                            <View style={{ width: "14%", textAlign: "right" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>${pricetoString(item.price * item.quantity)}</Text>
                            </View>
                        </View>
                    ))}

                    {/* {_.times(rows, () => (
                        <View style={{ height: 32, flexDirection: "row", borderBottom: "1px dashed #e5e7eb", padding: 10 }} />
                    ))} */}
                    {/* {itemsOnly ? null : */}
                    {/* } */}
                </View>
                <View wrap={false} style={{ justifyContent: "flex-end", marginTop: "auto", paddingBottom: 2 }}>
                    <View style={{ flexDirection: "col", paddingHorizontal: 10 }}>
                        {summary.map((item, index) => (
                            <View style={{ flexDirection: "row" }} id={index}>
                                <View style={{ width: "85%", textAlign: "right", paddingRight: 15, paddingVertical: 10, borderRight: "1px dashed #e5e7eb" }}>
                                    <Text style={[styles.text, { marginTop: 0 }]}>{item.title}</Text>
                                </View>
                                <View style={{ width: "15%", textAlign: "right", paddingLeft: 15, paddingVertical: 10 }}>
                                    <Text style={[styles.text, { marginTop: 0 }]}>${pricetoString(item.data)}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#dbeafe" }}>
                        <View style={{ width: "85%", textAlign: "right", paddingRight: 15, paddingVertical: 10 }}>
                            <Text style={[styles.text, { marginTop: 0, color: "#2563eb" }]}>Total</Text>
                        </View>
                        <View style={{ width: "15%", textAlign: "right", paddingLeft: 15, paddingVertical: 10 }}>
                            <Text style={[styles.text, { marginTop: 0, color: "#2563eb" }]}>${pricetoString(total)}</Text>
                        </View>
                    </View>
                    <Text style={[styles.footer, styles.text, { textAlign: "right", marginTop: 5 }]}>This document is private and confidential.</Text>
                </View>
            </Page>
        </Document>
    );

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <PopoutModal
            button={
                admin == true ?
                    <div className="cursor-pointer text-paragraph font-productsans-medium andie-dark-button">Download {itemsOnly ? "Quote" : account_type === 2 ? "P.O." : "Invoice"}</div>

                    :
                    <div
                        className="cursor-pointer text-paragraph font-productsans-medium text-blue-600"
                    >
                        Download {account_type === 2 ? "P.O." : itemsOnly ? "Quote" : "Invoice"}
                    </div>
            }
            title={`Download ${account_type === 2 ? "P.O." : itemsOnly ? "Quote" : "Invoice"}`}
        >
            <div className="">
                <PDFViewer className="max-w-[40vw] max-h-[80vh] pb-4" height={window.innerHeight} width={window.innerWidth}>
                    <MyDocument />
                </PDFViewer>
            </div>
        </PopoutModal>
    );

}