import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Toaster, toast } from "react-hot-toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
    BidAbout, BidBudget, BidConfirmation, BidContact, BidDetails,
    BidDietaryRequirements, BidPreferences
} from ".";
import { AccountsAPI, BiddingAPIs } from "../../../apis";
import { MenuButtons, bidTabs, dietaryOptions, industryOptions } from "../../common";
import { SaveIcon } from "../../../assets/icons";

export default function BiddingForm({ setDrafts, selectedDraft, user, savedBid, item, setCurrentStep }) {
    const [selectedTab, setSelectedTab] = useState("About");
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [step, setStep] = useState(0);

    // const [business, setBusiness] = useState(user.businessname);
    const [organization, setOrganization] = useState(user != null ? user.organization : "");
    const [industry, setIndustry] = useState(user != null ? user.industry : "Banking");

    const [bidTitle, setBidTitle] = useState(item.template_title);
    const [bidDescription, setBidDescription] = useState(item.template_description);
    const [eventType, setEventType] = useState("Office Breakfast");

    const [guestCount, setGuestCount] = useState(item.min_guests == undefined ? 0 : item.min_guests);
    const [cgeType, setCGEType] = useState(item.cge_type == null ? "Catering" : item.cge_type);
    const [restaurantRating, setRestaurantRating] = useState("Any");

    const [location, setLocation] = useState("");
    const [locationPlaceID, setLocationPlaceID] = useState("");
    const [locationGeometry, setLocationGeometry] = useState("");
    const [locationAdditionalDetails, setLocationAdditionalDetails] = useState("");
    const [locationSecondaryDetails, setLocationSecondaryDetails] = useState("");

    const [eventDateTime, setEventDateTime] = useState(moment(moment().toDate()).add(1, "d").add(60, 'minute').startOf('hour').toDate());
    const [deliveryTime, setDeliveryTime] = useState(moment(moment().toDate()).add(1, "d").add(60, 'minute').startOf('hour').toDate());
    const [endDateTime, setEndDateTime] = useState(moment(moment().toDate()).add(8, "d").add(60, 'minute').startOf('hour').toDate());
    const [recurringPeriod, setRecurringPeriod] = useState('month')
    const [recurring, setRecurring] = useState(false)

    const [restaurantType, setRestaurantType] = useState((item?.cuisine_type?.length == 0 || item.cuisine_type == null) ? ['Any'] : item.cuisine_type);
    const [dietaryPreferences, setDietaryPreferences] = useState([]);
    const [dietaryNotes, setDietaryNotes] = useState("");
    const [menuOptions, setMenuOptions] = useState(item.menu_options == undefined ? [] : item.menu_options)
    const [packagingType, setPackagingType] = useState(["Any"]);

    const [currency, setCurrency] = useState("CAD");
    const [budget, setBudget] = useState({ perHead: 100, total: 500, tipAmount:0 });
    const [tipStatus, setTipStatus] = useState(0);
    const [paymentTerms, setPaymentTerms] = useState("Prepaid in full");

    const [firstName, setFirstName] = useState(user != null ? user.first_name : "");
    const [lastName, setLastName] = useState(user != null ? user.last_name : "");
    const [email, setEmail] = useState(user != null ? user.email : "");
    const [phone, setPhone] = useState(user != null ? user.phone : "");
    const [contactTitle, setContactTitle] = useState(user != null ? user.job_title : "");

    const [expiryDate, setExpiryDate] = useState(moment(moment()).toDate());
    const [accessibility, setAccessibility] = useState({ "Sidewalk": false, "Parking": false, "Stairs": false, "Elevators": false })

    const [additionalNotes, setAdditionalNotes] = useState(item.custom_items ? makeJSONPretty(item.custom_items) : "");
    const [requiresChafing, setRequiresChafing] = useState(false);
    const [requiresStaffing, setRequiresStaffing] = useState(false);

    const [find, setFind] = useState("Select an option");
    const [comments, setComments] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
        if (item.custom_items) {
            let options = Object.keys(item.custom_items)
            setMenuOptions(options);
        }
    }, []);

    useEffect(() => {
        setExpiryDate(moment(moment(eventDateTime).toDate()).add(-4, "h").toDate())
    }, [eventDateTime]);

    useEffect(() => {
        if (selectedDraft) {
            setBidTitle(selectedDraft.bidTitle); setBidDescription(selectedDraft.bidDescription); setCGEType(selectedDraft.cgeType); setEventType(selectedDraft.eventType); setEventDateTime(moment(selectedDraft.eventDateTime).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setGuestCount(selectedDraft.guestCount); setLocation(selectedDraft.location); setLocationPlaceID(selectedDraft.locationPlaceID); setLocationGeometry(selectedDraft.locationGeometry); setLocationAdditionalDetails(selectedDraft.locationAdditionalDetails); setLocationSecondaryDetails(selectedDraft.locationSecondaryDetails); setExpiryDate(moment(selectedDraft.expiryDate).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setEndDateTime(moment(selectedDraft.endDateTime).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setRecurringPeriod(selectedDraft.recurringPeriod); setRecurring(selectedDraft.recurring); setBudget(selectedDraft.budget); setCurrency(selectedDraft.currency); setPaymentTerms(selectedDraft.paymentTerms); setRestaurantType(selectedDraft.restaurantType); setRestaurantRating(selectedDraft.restaurantRating); setDietaryPreferences(selectedDraft.dietaryPreferences); setDietaryNotes(selectedDraft.dietaryNotes); setMenuOptions(selectedDraft.menuOptions); setAccessibility(selectedDraft.accessibility); setPackagingType(selectedDraft.packagingType); setAdditionalNotes(selectedDraft.additionalNotes); setDeliveryTime(moment(selectedDraft.deliveryTime).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setRequiresChafing(selectedDraft.requiresChafing); setRequiresStaffing(selectedDraft.requiresStaffing);
        }
    }, [selectedDraft])

    useEffect(() => {
        if (selectedTab == 'About') {
            setCurrentStep(1)
        } else if (selectedTab == 'Bid Details') {
            setCurrentStep(2)
        } else if (selectedTab == 'Preferences') {
            setCurrentStep(3)
        } else if (selectedTab == 'Dietary Requirements') {
            setCurrentStep(4)
        } else if (selectedTab == 'Budget') {
            setCurrentStep(5)
        } else if (selectedTab == 'Contact') {
            setCurrentStep(6)
        } else if (selectedTab == 'Confirmation') {
            setCurrentStep(7)
        }
    }, [selectedTab])

    useEffect(() => {
        if (cgeType == "Dining" || cgeType == "Premium Dining") {
            setAccessibility({
                "Parking": false,
                "Accessible": false,
                "Transit": false,
                "Ride Share": false
            })
        } else {
            setAccessibility({
                "Sidewalk": false,
                "Parking": false,
                "Stairs": false,
                "Elevators": false,
                "Carts": false,
                "Food Trolly": false,
                "Service Entrance": false
            })
        }
    }, [cgeType])

    function makeJSONPretty(obj) {
        var tempstr = "Customized Items:"
        for (let index = 0; index < Object.keys(obj).length; index++) {
            const menuOption = Object.keys(obj)[index]
            tempstr += "    " + menuOption + ":"

            var innerStuff = obj[menuOption]
            for (let jndex = 0; jndex < Object.keys(innerStuff).length; jndex++) {
                var foodItem = Object.keys(innerStuff)[jndex]
                var qty = obj[menuOption][foodItem].quantity
                var included = obj[menuOption][foodItem].included
                if (included) {
                    tempstr += foodItem + " x" + qty + ", "
                }
            }
            tempstr = tempstr.slice(0, -2)
            tempstr += '      '
        }
        return tempstr
    }

    function shafingNotes() {
        const shaffing_note = '\nThis bid requires a chafing dish to be provided.';
        setRequiresChafing(!requiresChafing);

        if (requiresChafing) {
            setAdditionalNotes(additionalNotes + shaffing_note);
        } else {
            setAdditionalNotes(additionalNotes.replace(shaffing_note, ""));
        }

    }

    function transactionCode(length) {
        var result = '';
        // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!%$#?&';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }

        return result;
    }

    const menuButtonOptions = [
        'About',
        'Bid Details',
        'Preferences',
        'Dietary Requirements',
        'Budget',
        'Contact',
        'Confirmation',
    ]


    const submitBid = async () => {
        // converting restaurant type list to a simple array for saving in the backend instead of a dictionary
        const restaurant_type_list = []
        restaurantType.forEach(element => {
            restaurant_type_list.push(element)
        });
        
        budget.total = budget.perHead * guestCount;
        budget.tipAmount = tipStatus/100;

        var payloadRecurring = recurringPeriod
        if (!recurring) {
            payloadRecurring = 'none'
        }

        //if statement
        if (!bidTitle || !bidDescription || !location || isEmpty(dietaryPreferences)) {
            if (!bidTitle) {
                toast.error("Bid Title needs to be filled!")
            }
            if (!bidDescription) {
                toast.error("Bid description needs to be filled.")
            }
            if (!location) {
                toast.error("Location needs to be filled.")
            }
            if (isEmpty(dietaryPreferences)) {
                toast.error("Dietary Preferences needs to be filled.")
            }
        } else {

            const payload = {
                active: true,
                organization: organization,
                industry: industry,
                cge_email: user != null ? user.email : email,

                //contact information
                contact_first_name: firstName,
                contact_last_name: lastName,
                contact_title: contactTitle,
                contact_phone: phone,
                contact_email: email,

                //basic bid information
                bid_title: bidTitle,
                bid_description: bidDescription,
                cge_type: cgeType,
                event_type: eventType,

                // logistics
                event_date_time: eventDateTime,
                guest_count: guestCount,
                location: location,
                locationPlaceID: locationPlaceID,
                locationGeometry: locationGeometry,
                locationAdditionalDetails: locationAdditionalDetails,
                locationSecondaryDetails: locationSecondaryDetails,

                expiry_date: expiryDate,
                end_date: endDateTime,
                recurring_period: recurringPeriod,
                // recurring: recurring,

                // financial details
                budget: budget,
                currency: currency,
                payment_terms: paymentTerms,

                //restaurant selection
                restaurant_type: restaurant_type_list,

                restaurant_rating: restaurantRating,
                dietary_preferences: dietaryPreferences,
                dietary_notes: dietaryNotes,
                menu_options: menuOptions,
                accessibility: accessibility,
                packaging_type: packagingType,

                //confirmation code
                transaction_code: transactionCode(6),

                additional_notes: additionalNotes,
                cge_representative: user != null ? user.cge_representative : 'No Representative',
                requires_chafing: requiresChafing,
                requires_staffing: requiresStaffing,
                delivery_time: deliveryTime

            }
            
            if (user != null) {
                if (recurring) {
                    await BiddingAPIs.openRecurringBid(payload).then((res) => {
                        savedBid();
                    }).catch(() => {
                        // alert('Could not create your bid. Try again!')
                    });
                } else {
                    await BiddingAPIs.openBid(payload).then((res) => {
                        savedBid();
                    }).catch(() => {
                    });
                }
            } else {
                const requestPayload = {
                    organization: organization,
                    first_name: firstName,
                    last_name: lastName,
                    title: contactTitle,
                    email: email,
                    industry: industry,
                    phone: phone,
                    find: find,
                    comments: comments,
                    bid_payload: payload
                }

                await AccountsAPI.requestAccessCode(requestPayload).then((res) => {
                    navigate('/success-request');
                })
            }
        }
    }

    const saveDraft = async () => {
        const draft = { bidTitle, bidDescription, cgeType, eventType, eventDateTime, guestCount, location, locationPlaceID, locationGeometry, locationAdditionalDetails, locationSecondaryDetails, expiryDate, endDateTime, recurringPeriod, recurring, budget, currency, paymentTerms, restaurantType, restaurantRating, dietaryPreferences, dietaryNotes, menuOptions, accessibility, packagingType, additionalNotes, deliveryTime, requiresChafing, requiresStaffing }

        // Retrieve existing drafts from local storage
        const existingDrafts = JSON.parse(localStorage.getItem('drafts')) || [];

        // Add the new draft to the array
        const newDrafts = [...existingDrafts, draft];

        // Save the updated drafts array to local storage
        localStorage.setItem('drafts', JSON.stringify(newDrafts));
        setDrafts(newDrafts);
    }

    const ideas = [
        {
            "title": "Team lunch on the 29th",
            "headcount": "50",
            "description": "We are hosting a team lunch on the 29th and we will like to have a diverse selection of meals that we can serve to the team. There are no specific dietary restrictions but please include options for vegetarian, gluten-free, and more in the responses. Thinking Indian, Thai, or Healthy Bowls.",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "1750.00",
            "cuisine": "Healthy bowls, Indian, Thai"
        },
        {
            "title": "Seminar lunch and refreshments",
            "headcount": "400",
            "description": "We are conducting a seminar for digital professionals and we are looking for a caterer or restaurant that can provide individually packed meals for the event. Ideally something light that would be delicious and appealing to all the attendees. Our ideas are sandwiches, wraps, or healthy bowls.",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "14000.00",
            "cuisine": "Any"
        },
        {
            "title": "Company summer day",
            "headcount": "65",
            "description": "We are looking for a nice restaurant with a patio or private space where we can host our annual Company Summer day. Every year we gather our employees from across the country for a day of giving, celebration, and team building. The goal would be to find a nice restaurant where we can conclude the day's festivities.",
            "cge_type": "Premium Dining",
            "event_type": "Team outing",
            "budget": "18000.00",
            "cuisine": "Fine dining"
        },
        {
            "title": "Stakeholders' dinner",
            "headcount": "90",
            "description": "I need a restaruant where we can host a dinner / mixer for 90 guests. These are important VIPs from our stakeholder network, so the ideal location must be a premium restaurant. Open to any ideas on cuisines.",
            "cge_type": "Dining",
            "event_type": "Team outing",
            "budget": "9000.00",
            "cuisine": "Any"
        },
        {
            "title": "Annual Summer Festival",
            "headcount": "2000",
            "description": "We are hosting our Annual Summer festival on the 18th of next month and we are looking for 5 or 6 vendors that can help us put together the food menu focused on summer shareables and bites, and drinks, and desserts.",
            "cge_type": "Catering Plus",
            "event_type": "Happy hour",
            "budget": "76000.00",
            "cuisine": "Caterer"
        },
        {
            "title": "Team hands-on breakfast",
            "headcount": "30",
            "description": "Planning a team breakfast a few Thursdays from now. We have a handful of our team in the office for an hand-ons day and we would like to kick things off with some delicious meals and bites.  ",
            "cge_type": "Catering Plus",
            "event_type": "Office breakfast",
            "budget": "800.00",
            "cuisine": "Breakfast"
        },
        {
            "title": "Team hands-on lunch",
            "headcount": "30",
            "description": "Planning a team lunch a few Thursdays from now. Our team in the office for an hand-ons day and we would like to serve a nice lunch for everybody to enjoy.  ",
            "cge_type": "Catering Plus",
            "event_type": "Lunch",
            "budget": "1100.00",
            "cuisine": "Any"
        },
        {
            "title": "Lunch",
            "headcount": "125",
            "description": "Company lunch for 120 - 125 people later this month and we are looking for a few nice options that we can offer. Opening to all ideas but leaning towards Thai or Carribbean.",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "3750.00",
            "cuisine": "Thai, Carribbean"
        },
        {
            "title": "AGM Lunch",
            "headcount": "150",
            "description": "Our AGM is coming up in two months on the 17th and we are looking for menu options. The headcount we are expecting is about 150 guests, so we are looking for entrees that are preferably diverse enough for a large audience. We are flexible on budget so far as the submissions are enticing.",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "7500.00",
            "cuisine": "Carribean, Italian, Indian, Japanese, Chinese"
        },
        {
            "title": "Monthly innovation mixer",
            "headcount": "65",
            "description": "Monthly innovation mixer for executives and founders in the tech space. I'm searching for restaurant preferably Japanese or Italian that where we can host up to 65 folks. We are okay with a simple spread and bar menu with house beer, wine, and cocktail options.",
            "cge_type": "Dining",
            "event_type": "Team outing",
            "budget": "7500.00",
            "cuisine": "Japanese"
        },
        {
            "title": "Cocktail hour for 90",
            "headcount": "90",
            "description": "We are having a cocktail reception on the 22nd of next month and we are looking for a nice spread preferably finger foods that's appropriate for a business casual event. Our headcount is around 90 people, we are currently flexible on budget. If possible we would love to have a server that can help pass around the beverages.",
            "cge_type": "Premium Dining",
            "event_type": "Networking event",
            "budget": "4500.00",
            "cuisine": "Any"
        },
        {
            "title": "Client lunch for 4",
            "headcount": "4",
            "description": "Hosting an important client at our offices next month and we are looking for a premium catering offering for lunch. The idea would be individually packed meals that can be enjoyed during a \"working lunch\".",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "400.00",
            "cuisine": "Any"
        },
        {
            "title": "Small team lunch",
            "headcount": "15",
            "description": "Looking for restaurant options for a team lunch next month. Interested in healthy bowls that can be inclusive of everyone's dietary preferences as much as possible.",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "500.00",
            "cuisine": "Healthy bowls"
        },
        {
            "title": "Lunch for visiting international delegation",
            "headcount": "20",
            "description": "We are hosting an international delegation from South Korea on the 2nd of next month and we are looking for impressive catering options that are a blend of Canadian cuisine, which reflect the multicultural nature of Canada, and Korean cuisine that our guests will be familiar with.",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "2000.00",
            "cuisine": "Any"
        },
        {
            "title": "Dinner for visiting clients",
            "headcount": "20",
            "description": "As mentioned in the other bid, we have a client team visiting from South Korea and we are looking for premium restaurant options that we can use to wow them. We are thinking about $150 per head, but we are flexible to move the budget for the right options.",
            "cge_type": "Premium Dining",
            "event_type": "Team outing",
            "budget": "3000.00",
            "cuisine": "Any"
        },
        {
            "title": "Tech Innovation Mixer",
            "headcount": "350",
            "description": "We are looking for finger foods and refreshments for an event on the 17th at 7.00pm. The estimated guests is about 200 - 350, our budget is tight so please stick to the specified amount and please ensure there are more than enough vegetarian options included.",
            "cge_type": "Catering",
            "event_type": "Networking event",
            "budget": "8750.00",
            "cuisine": "Caterer"
        },
        {
            "title": "Team lunch on the 25th of next month",
            "headcount": "25",
            "description": "Team lunch for 25 people on 25th of next month. We are looking for a caterer or restaurant that can have the meals delivered warm in buffet trays.",
            "cge_type": "Catering",
            "event_type": "Lunch",
            "budget": "875.00",
            "cuisine": "Any"
        },
        {
            "title": "Team dinner on the 25th of next month",
            "headcount": "25",
            "description": "Team dinner for people on the 25th of next month. Not picky about cuisine options so far as it’s a nice ambience. Please submit the bid without gratuity as we've been told that would be automatically calculated by the platform.",
            "cge_type": "Dining",
            "event_type": "Team outing",
            "budget": "4000.00",
            "cuisine": "Any"
        },
        {
            "title": "Client dinner meeting",
            "headcount": "5",
            "description": "We have a small client dinner and we are looking for a nice restaurant like a Steakhouse where our guests can enjoy a great meal around $300 per person.",
            "cge_type": "Premium Dining",
            "event_type": "Client meeting",
            "budget": "1500.00",
            "cuisine": "Steakhouse"
        },
        {
            "title": "Innovation Breakfast",
            "headcount": "100",
            "description": "Our monthly innovation roundtable breakfast where we bring together industry leaders and experts in the health technology industry to discuss the latest trends and exchange ideas is coming up and we are searching for meal options. The event is typically three hours long, so we are looking for meals and refreshments that could be served for the entire duration. Ideally we would want the food served in batches i.e. maybe coffee as our guests arrive, then breakfast, and eventually some pastries as they leave.",
            "cge_type": "Catering Plus",
            "event_type": "Office breakfast",
            "budget": "2700.00",
            "cuisine": "Breakfast"
        },
        {
            "title": "Conference Happy Hour",
            "headcount": "550",
            "description": "Exhibiting at Collision and we are looking for a happy hour experience that we can host in our booth for 550 people somewhere close to the conference or transit accessible for attendees.",
            "cge_type": "Dining",
            "event_type": "Networking event",
            "budget": "22000.00",
            "cuisine": "Any"
        },
        {
            "title": "Small client reception",
            "headcount": "65",
            "description": "We have a small client reception on 16th and we are looking for light refreshments for the event. We would like a nice combination of meats, fruits, and other handhelds preferably but we are also open to suggestions from the caterers.",
            "cge_type": "Catering",
            "event_type": "Networking event",
            "budget": "3250.00",
            "cuisine": "Any"
        },
        {
            "title": "Dinner for 40",
            "headcount": "40",
            "description": "I need a restaruant for a team dinner in a few weeks. Expecting about 40 people and open to cuisines so far as it can be a diverse menu that can also serve vegetarians and vegan guests. ",
            "cge_type": "Dining",
            "event_type": "Team outing",
            "budget": "6000.00",
            "cuisine": "Any"
        },
        {
            "title": "Team happy hour",
            "headcount": "21",
            "description": "I'm arranging our quarterly team Happy Hour. Looking for a nice spot that is central as much as possible, where we can enjoy delicious food and potentially find space on a Thursday afternoon in the summer. ",
            "cge_type": "Premium Dining",
            "event_type": "Happy hour",
            "budget": "2500.00",
            "cuisine": "Any"
        },
        {
            "title": "Meal and refreshments for youth workshop",
            "headcount": "600",
            "description": "Planning an in-person lunch-and-learn for youths within our ecosystem and we are looking for a caterer that can help us put together a nice spread of meeting refreshments and pastries, as well as lunch for the event.",
            "cge_type": "Catering Plus",
            "event_type": "Lunch",
            "budget": "21500.00",
            "cuisine": "Any"
        },
        {
            "title": "Pitch Night 2024",
            "headcount": "150",
            "description": "On the 18th of next month, we will be hosting our yearly pitch night for up-and-coming startups in the Toronto ecosystem. We are looking for one or two vendors that can help us put together finger foods and desserts for the event. We are also looking for beverages and two bartenders for the event. We anticipate we might need to work with more than one vendor on this but if its possible to have both the food and beverages caterers by one caterer that would be our most preferred option.",
            "cge_type": "Catering",
            "event_type": "Networking event",
            "budget": "8000.00",
            "cuisine": "Caterer"
        },
        {
            "title": "Female leaders panel and fireside",
            "headcount": "250",
            "description": "Our event is happening is an evening of networking and panel conversations where we are bringing tech and entertainment leaders to celebrate female voices and impact players at the cross section of tech and media. We are interested for catered refreshments for the occasion, preferably from a female-owned business.",
            "cge_type": "Catering Plus",
            "event_type": "Networking event",
            "budget": "6000.00",
            "cuisine": "Caterer"
        },
        {
            "title": "Office happy hour next month",
            "headcount": "100",
            "description": "Office happy hour next month. We would like to have some charcuterie boards, wine, and beers that we can set out while we mingle. Also open to any additional recommendations from caterers or restaurants on finger foods.",
            "cge_type": "Catering",
            "event_type": "Happy hour",
            "budget": "4000.00",
            "cuisine": "Any"
        }
    ]

    async function seedData() {
        for (let a = 0; a < ideas.length; a++) {
            const i = ideas[a];

            // Initialize budget variables
            let totalBudget = 0;
            let totalHeadcount = 0;

            totalBudget += parseFloat(i.budget);
            totalHeadcount += parseInt(i.headcount);

            const packaging = ["Any", "Buffet / Food Station", "Individually"];
            const ratings = ["Any", "3.0 - 4.0 stars", "4.0 - 5.0 stars"]


            // Initialize cuisine type array
            let cuisineTypes = [];

            // Split cuisine types and add to cuisineTypes array
            const cuisines = i.cuisine.split(',').map(cuisine => cuisine.trim());
            cuisineTypes = [...new Set([...cuisineTypes, ...cuisines])];

            const event_time = generateRandomWeekdayDateTime(5, i.event_type);

            const payload = {
                "active": true,
                "organization": "Baba's Test Organization",
                "industry": industryOptions[Math.floor(Math.random() * industryOptions.length)].name,
                "cge_email": "baba22@andie.work",
                "contact_first_name": "Baba",
                "contact_last_name": "Ajayi",
                "contact_title": "Director",
                "contact_phone": "647-783-9571",
                "contact_email": "baba22@andie.work",
                "bid_title": i.title,
                "bid_description": i.description,
                "cge_type": i.cge_type,
                "event_type": i.event_type,
                "event_date_time": event_time,
                "guest_count": i.headcount,
                "location": "Downtown Toronto, Toronto, ON, Canada",
                "locationPlaceID": "ChIJvRBz0jTL1IkRkwMHIgbSFbo",
                "locationGeometry": "{\"lat\":43.6548046,\"lng\":-79.3883031}",
                "locationAdditionalDetails": "Toronto, ON CA",
                "locationSecondaryDetails": "",
                "expiry_date": event_time,
                "end_date": event_time,
                "recurring_period": "month",

                "budget": {
                    "perHead": parseFloat((totalBudget / totalHeadcount).toFixed(2)),
                    "total": parseFloat(totalBudget),
                    "tipAmount": 0
                },
                "currency": "CAD",
                "payment_terms": "Prepaid in full",
                "restaurant_type": cuisineTypes,
                "restaurant_rating": i.cge_type.includes("Dining") ? "4.0 - 5.0 stars" : ratings[Math.floor(Math.random() * ratings.length)],
                "dietary_preferences": shuffleArray(dietaryOptions).slice(0, Math.floor(Math.random() * dietaryOptions.length)),
                "dietary_notes": "",
                "menu_options": generateMenuOptions(i.event_type),
                "accessibility": {
                    "Sidewalk": true,
                    "Parking": true,
                    "Stairs": false,
                    "Elevators": i.cge_type.includes("Catering") ? true : false,
                    "Carts": i.cge_type.includes("Catering") ? true : false,
                    "Food Trolly": i.cge_type.includes("Catering") ? true : false,
                    "Accessible": i.cge_type.includes("Dining") && true,
                    "Transit": i.cge_type.includes("Dining") && true,
                    "Ride Share": i.cge_type.includes("Dining") && true,
                    "Service Entrance": i.cge_type.includes("Catering") ? true : false
                },
                "packaging_type": [packaging[Math.floor(Math.random() * packaging.length)]],

                "transaction_code": transactionCode(6),
                "additional_notes": "",
                "cge_representative": "baba.aj@andie.work",
                "requires_chafing": false,
                "requires_staffing": false,
                "delivery_time": event_time
            }

            await BiddingAPIs.openBid(payload).then((res) => {
                savedBid();
            }).catch(() => {
            });
        }
    }

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    function generateMenuOptions(mealType) {
        if (mealType.includes("Lunch") ) {
            return ["Main"]
        } else if (mealType.includes("Happy hour") || mealType.includes("Networking event")) {
            return ["Appetizer and Shareables", "Dessert", "Beverages"]
        } else if (mealType.includes("Office breakfast")) {
            return ["Side", "Main","Beverages"]
        } else {
            return ["Appetizer and Shareables","Side","Dessert","Main","Beverages"]
        }
    }

    function generateRandomWeekdayDateTime(nextMonth, mealType) {
        // Get current date
        const currentDate = new Date();
      
        // Get current year
        const currentYear = currentDate.getFullYear();
      
        // Calculate next month's date
        let nextMonthDate = new Date(currentYear, nextMonth, 1);
      
        // Generate a random day between 1 and the last day of next month
        const randomDay = Math.floor(Math.random() * (getDaysInMonth(nextMonthDate) - 1)) + 1;
      
        // Set the date to the generated random day
        nextMonthDate.setDate(randomDay);
      
        // Adjust time based on meal type
        let hours, minutes;
        if (mealType.toLowerCase().includes("breakfast")) {
            hours = 8; // 8:00 AM EST
            minutes = 0;
        } else if (mealType.toLowerCase().includes('lunch')) {
            hours = 12; // 12:00 PM EST
            minutes = 0;
        } else if (mealType.toLowerCase().includes("happy hour") || mealType.toLowerCase().includes("networking event")) {
            hours = 18; // 6:00 PM EST
            minutes = 0;
        } else {
            // Default to dinner at 7:00 PM EST
            hours = 19;
            minutes = 0;
        }

        // Convert EST hours to GMT
        hours += 5; // For EST, add 5 hours to get GMT
        if (isDaylightSavingTime(nextMonthDate)) {
            // Add an additional hour for daylight saving time (EDT)
            hours += 1;
        }
    
        // Set time
        nextMonthDate.setUTCHours(hours, minutes, 0, 0);
      
        // Ensure generated date is a weekday
        while (nextMonthDate.getDay() === 0 || nextMonthDate.getDay() === 6) {
            // If generated date is a Sunday or Saturday, add one day
            nextMonthDate.setDate(nextMonthDate.getDate() + 1);
        }
      
        return nextMonthDate.toISOString();
    }
      
    // Helper function to get the number of days in a month
    function getDaysInMonth(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    // Helper function to check if a date is in daylight saving time (EDT)
    function isDaylightSavingTime(date) {
        const start = new Date(Date.UTC(date.getUTCFullYear(), 2, 8)); // March 8th
        const end = new Date(Date.UTC(date.getUTCFullYear(), 10, 1)); // November 1st
        start.setUTCDate(8 - ((start.getUTCDay() + 6) % 7));
        end.setUTCDate(1 - ((end.getUTCDay() + 6) % 7));
        return date.getTime() >= start.getTime() && date.getTime() < end.getTime();
    }  
    
    // seedData()

    return (
        <>
            <div className="h-full flex flex-col w-full">
                {bidTabs.map((item, index) => (
                    <>
                        {item.title === selectedTab &&
                            <div className="h-12 flex justify-between">
                                <div>
                                    {selectedTab !== "Confirmation" ?
                                        <h6 className="text-xs font-roboto-medium text-gray-400 uppercase">Step {index + 1} of {bidTabs.length - 1}.</h6>
                                        :
                                        <h6 className="text-xs font-roboto-medium text-gray-400 uppercase">Final Step.</h6>
                                    }
                                    <h6 className="text-base font-productsans-medium text-gray-700">{item.explanation}</h6>
                                </div>
                                <div onClick={() => saveDraft()} className="inline-flex space-x-1.5 items-center rounded-sm bg-gray-100 h-8 px-2.5 hover:bg-blue-100 cursor-pointer">
                                    <SaveIcon height={14} color="#2563eb" />
                                    <p className="font-productsans-medium text-paragraph text-blue-600">Save draft</p>
                                </div>
                            </div>
                        }
                        
                    </>
                ))}
                {/* [calc(100%_-_48px)] */}
                <div className="h-[74%] w-full pt-3 pb-10 md:pb-0 md:h-full overflow-y-scroll scrollbar-hide">
                    {selectedTab === 'About' && <BidAbout bidTitle={bidTitle} bidDescription={bidDescription} setBidTitle={setBidTitle} setBidDescription={setBidDescription} cgeType={cgeType} setCGEType={setCGEType} setSelectedTab={setSelectedTab} item={item} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />} {/* Item implemented! */}

                    {selectedTab === 'Bid Details' && <BidDetails guestCount={guestCount} location={location} setGuestCount={setGuestCount} eventType={eventType} setEventType={setEventType} setLocation={setLocation} setLocationPlaceID={setLocationPlaceID} setLocationGeometry={setLocationGeometry} setLocationAdditionalDetails={setLocationAdditionalDetails} cgeType={cgeType} eventDateTime={eventDateTime} setEventDateTime={setEventDateTime} setSelectedTab={setSelectedTab} item={item} accessibility={accessibility} setAccessibility={setAccessibility} endDateTime={endDateTime} setEndDateTime={setEndDateTime} recurringPeriod={recurringPeriod} setRecurringPeriod={setRecurringPeriod} recurring={recurring} setRecurring={setRecurring} setLocationSecondaryDetails={setLocationSecondaryDetails} locationSecondaryDetails={locationSecondaryDetails} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} deliveryTime={deliveryTime} setDeliveryTime={setDeliveryTime} />} {/* Item implemented! */}

                    {selectedTab === 'Preferences' && <BidPreferences setRestaurantType={setRestaurantType} setMenuOptions={setMenuOptions} setRestaurantRating={setRestaurantRating} restaurantRating={restaurantRating} restaurantType={restaurantType} menuOptions={menuOptions} setSelectedTab={setSelectedTab} item={item} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Dietary Requirements' && <BidDietaryRequirements dietaryPreferences={dietaryPreferences} setDietaryPreferences={setDietaryPreferences} dietaryNotes={dietaryNotes} setDietaryNotes={setDietaryNotes} setSelectedTab={setSelectedTab} packagingType={packagingType} setPackagingType={setPackagingType} cge_type={cgeType} requiresChafing={requiresChafing} setRequiresChafing={setRequiresChafing} requiresStaffing={requiresStaffing} setRequiresStaffing={setRequiresStaffing} shafingNotes={shafingNotes} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Budget' && <BidBudget cgeType={cgeType} tipStatus={tipStatus} setTipStatus={setTipStatus} currency={currency} budget={budget} paymentTerms={paymentTerms} setPaymentTerms={setPaymentTerms} guestCount={guestCount} setBudget={setBudget} setCurrency={setCurrency} setSelectedTab={setSelectedTab} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Contact' && <BidContact comments={comments} setComments={(e) => setComments(e)} submitBid={submitBid} firstName={firstName} lastName={lastName} email={email} phone={phone} contactTitle={contactTitle} setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} setContactTitle={setContactTitle} setPhone={setPhone} industry={industry} setIndustry={setIndustry} setSelectedTab={setSelectedTab} additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes} organization={organization} setOrganization={setOrganization} find={find} setFind={setFind} requiresChafing={requiresChafing} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Confirmation' && <BidConfirmation cgeType={cgeType} firstName={firstName} budget={budget} guestCount={guestCount} setCGEType={setCGEType} expiryDate={expiryDate} eventDateTime={eventDateTime} setExpiryDate={setExpiryDate} setSelectedTab={setSelectedTab} submitBid={submitBid} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} step={step} setStep={setStep} func={setSelectedTab} menuButtonOptions={menuButtonOptions} />}
                </div>
                {selectedTab != 'Confirmation' && <MenuButtons func={setSelectedTab} check={isNextButtonDisabled} step={step} setStep={setStep} menuButtonOptions={menuButtonOptions} />}
            </div>
            
        </>
    )
}