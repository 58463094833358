import React, { useState } from 'react';
import { Modal } from "react-responsive-modal";
import { AccountsAPI } from '../../../apis';
import { toast } from 'react-hot-toast';
import { ThreeDots } from "react-loader-spinner";
import {password_styles, header_styles_mobile, header_styles} from '..'

function NewAccountPassword({ passwordModalOpened, setPasswordModalOpened }) {
    const [modalPassword, setModalPassword] = useState("");
    const [modalRePassword, setModalRePassword] = useState("");
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(window.localStorage.getItem("user_details"));


    const handleSetPassword = async (e) => {
        setLoading(true);
        if (modalPassword == modalRePassword) {

            const payload = {
                password: modalPassword,
            }

            try {
                var result = await AccountsAPI.changePassword(payload);

                if (result.status === 200) {
                    let user_details = user;

                    // Remove the first_time_login property
                    delete user_details.first_time_login;

                    // Convert the modified object back to a JSON string and update it in local storage
                    localStorage.setItem('user_details', JSON.stringify(user_details));

                    setPasswordModalOpened(false);
                    setLoading(false);
                }
            } catch (e) {

            }

        } else {
            toast.error("Passwords do not match");
            setLoading(false);
        }
    };

    return (
        <Modal
                blockScroll={false}
            open={passwordModalOpened}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
            styles={window.innerWidth < 768 ? header_styles_mobile : password_styles}
            center
        >
            <>
                <div className="flex h-full flex-col justify-between p-6">
                    <div>
                        <h6 className="mb-8 font-roboto-bold text-blue-600 text-xs uppercase">setup</h6>
                        <h1 className="text-2xl font-productsans-bold">
                            Hi {user?.first_name}, welcome to Andie!
                        </h1>
                        <p className="mt-3 andie-description-text text-gray-500 w-4/5 leading-tight">
                            We are delighted to be your preferred platform as you create great guest experiences for you and your colleagues.
                        </p>
                        <p className="mt-5 andie-description-text text-gray-500 w-4/5 leading-tight">
                            To get started, add your password.
                        </p>
                        <div className="mt-8 flex flex-col gap-y-5 w-full items-center">
                            <label className="flex flex-col w-full">
                                <h6 className="bid-form-input-label">Create Password</h6>
                                <div className="flex gap-x-2 w-full">
                                    <input
                                        className="bid-form-input w-full h-10"
                                        type="password"
                                        placeholder="******"
                                        value={modalPassword}
                                        onChange={(e) => setModalPassword(e.target.value)}
                                    ></input>
                                </div>
                            </label>
                            <label className="flex flex-col w-full">
                                <h6 className="bid-form-input-label">Re-enter Password</h6>
                                <div className="flex gap-x-2 w-full">
                                    <input
                                        className="bid-form-input w-full h-10"
                                        type="password"
                                        placeholder="******"
                                        value={modalRePassword}
                                        onChange={(e) => setModalRePassword(e.target.value)}
                                    ></input>
                                </div>
                            </label>
                            <button
                                className="andie-dark-button w-3/5"
                                onClick={handleSetPassword}
                            >
                                {loading ?
                                    <ThreeDots
                                        height="30"
                                        width="30"
                                        radius="9"
                                        color="#fff"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                    :
                                    'Submit'
                                }
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div className="andie-text-button">Why am I seeing this message?</div>
                    </div>
                </div>
            </>
        </Modal>
    );
}

export default NewAccountPassword;
