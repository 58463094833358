import React, { useState } from "react";
import { AccountsAPI } from "../../apis";

export default function Notifications() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [emailNotifications, setEmailNotifications] = useState(user.email_notifications);
    const [pushNotifications, setPushNotifications] = useState(false);
    const [smsNotifications, setSmsNotifications] = useState(true);

    const handleEmailNotificationChange = () => {
        setEmailNotifications(!emailNotifications);
    };

    const handlePushNotificationChange = () => {
        setPushNotifications(!pushNotifications);
    };

    const handleSmsNotificationChange = () => {
        setSmsNotifications(!smsNotifications);
    };

    async function editAccount() {
        if (user.account_type == 1) {
            AccountsAPI.editCGEUser({email_notifications: emailNotifications, email: user.email})
        } else {
            AccountsAPI.editBusinessUser({email_notifications: emailNotifications, email: user.email})
            // console.log("asdlnasdlnajkl")
        }
    }


    return (
        <div className="w-full md:w-4/5 lg:w-3/5">
            <h6 className="hidden md:inline-flex andie-sidebar-title">Notifications</h6>
            <div className="md:w-5/6 xl:w-3/4 space-y-5">
                
                <div className="mt-5 flex items-start">
                    <input
                        type="checkbox"
                        id="emailNotifications"
                        checked={emailNotifications}
                        onChange={handleEmailNotificationChange}
                        className="mt-1 mr-2 rounded-sm border-2 border-gray-200"
                    />
                    <div className="ml-3">
                        <h6 className="text-sm font-productsans-medium">
                            Email Notifications
                        </h6>
                        <p className="andie-description-text text-gray-500 pr-6">
                            Receive email updates about bids, responses, transactions, and the latest templates available on Andie.
                        </p>
                    </div>
                </div>
                {/* <div className="flex items-start">
                    <input
                        type="checkbox"
                        id="pushNotifications"
                        checked={pushNotifications}
                        onChange={handlePushNotificationChange}
                        className="mt-1 mr-2 rounded-sm border-2 border-gray-200"
                    />
                    <div className="ml-3">
                        <h6 className="text-sm font-productsans-medium">
                            Push Notifications
                        </h6>
                        <p className="andie-description-text text-gray-500 pr-6">
                            Receive notifications about your bids, responses, transactions, and more directly on your desktop.
                        </p>
                    </div>
                </div> */}
                {/* <div className="flex items-start">
                    <input
                        type="checkbox"
                        id="smsNotifications"
                        checked={smsNotifications}
                        onChange={handleSmsNotificationChange}
                        className="mt-1 mr-2 rounded-sm border-2 border-gray-200"
                    />
                    <div className="ml-3">
                        <h6 className="text-sm font-productsans-medium">
                            SMS Notifications
                        </h6>
                        <p className="andie-description-text text-gray-500 pr-6">
                            Receive SMS messages including information about bids, responses, transactions, Andie templates, and more.
                        </p>
                    </div>
                </div> */}
            </div>
            <div className="flex justify-end mt-12">
                <button onClick={() => editAccount()} className="andie-dark-button w-[25%]"> Save </button>
            </div>
        </div>
    )
}