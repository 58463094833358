import React, { useEffect, useState } from "react";
import { SidebarRight } from "../../components/navigation";
import { Link } from "react-router-dom";
import supporticon from "../../assets/images/support-icon.jpg";
import { SupportChat } from "../../components/external";
import axios from "axios";

export default function Support() {
    const contacts = [
        {
            title: "Guests",
            email: "guestsupport@hellopluck.com",
            phone: "947 234 4567",
            tollfree: "1-888-234-2000",
            description: "Report any guest issues or request additional guest support."

        },
        {
            title: "Restaurants & Caterers",
            email: "partnersupport@hellopluck.com",
            phone: "947 234 4567",
            tollfree: "1-888-234-2000",
            description: "Help and support executing your bookings or responding to bids."
        },
        {
            title: "Organizations",
            email: "corporatesupport@hellopluck.com",
            phone: "947 234 4567",
            tollfree: "1-888-234-2000",
            description: "Issues with your bid, responses, or bookings? Reach out."
        }
    ]

    const hoursOfOperation = [
        { day: 'Monday', hours: '7:00 A.M. - 9:00 P.M.' },
        { day: 'Tuesday', hours: '7:00 A.M. - 9:00 P.M.' },
        { day: 'Wednesday', hours: '7:00 A.M. - 9:00 P.M.' },
        { day: 'Thursday', hours: '7:00 A.M. - 9:00 P.M.' },
        { day: 'Friday', hours: '7:00 A.M. - 9:00 P.M.' },
        { day: 'Saturday', hours: '9:00 A.M. - 6:00 P.M.' },
        { day: 'Sunday', hours: 'Closed' }
    ];

    const [faq, setFaq] = useState([
        {
            question: 'How do I reset my password?',
            answer: 'If you have forgotten your password, please visit <a href="https://app.pluckbids.com/forgot-password" class="text-blue-600 font-roboto-medium transition-all duration-200 hover:underline">forgot password</a> page and enter the email address associated with your Andie account. <br /><br /> A reset password email will be sent to you if your email matches that of an account.',
            open: false
        },
        {
            question: 'Can I change my email address?',
            answer: 'You cannot change your email address directly from your Account page. To change your email, please contact your Andie account representative or reach out to us at <a href="mailto:support@hellopluck.com" class="text-blue-600 font-roboto-medium transition-all duration-200 hover:underline">support@hellopluck.com</a>.',
            open: false
        },
        {
            question: 'How do I create an account?',
            answer: `You can request an access code to Andie if you are an organization by visiting the <a href="https://app.pluckbids.com/signup" class="text-blue-600 font-roboto-medium transition-all duration-200 hover:underline">signup</a> page. If you are a restaurant or caterer, request access using <a href="https://pluckbids.com/become-a-partner" class="text-blue-600 font-roboto-medium transition-all duration-200 hover:underline">this form</a>.`,
            open: false
        },
        {
            question: 'How long does it take to receive an access code?',
            answer: 'If your business qualifies, access codes are granted within <b>1-2 business days</b>. If you would like to create a bid, you can do so before you receive your access code by visiting the <a href="https://app.pluckbids.com/cge/create-a-bid" class="text-blue-600 font-roboto-medium transition-all duration-200 hover:underline">create a bid</a> page.',
            open: false
        },
        {
            question: 'Does Andie have a subscription fee to use the platform?',
            answer: 'There are no subscription fees to access, open a bid, or submit a response to a bid on Andie.',
            open: false
        },
        {
            question: 'How do I view prices for bids and responses?',
            answer: 'Only accepted users are allowed to view pricing information on Andie. <a href="https://app.pluckbids.com/signup" class="text-blue-600 font-roboto-medium transition-all duration-200 hover:underline">Signup</a> or <a href="https://pluckbids.com/become-a-partner" class="text-blue-600 font-roboto-medium transition-all duration-200 hover:underline">become a partner</a> to view pricing information.',
            open: false
        },
        {
            question: 'Can I edit a bid?',
            answer: 'To edit your bid, navigate to the bid from the Explore or Activity page and click on the Edit Bid button.',
            open: false
        },
        {
            question: 'Can I change or update my responses?',
            answer: 'You can update or make changes to your submitted responses. Simply navigate to the bid and click on your response and the Edit Response button.',
            open: false
        },
        {
            question: 'Can I cancel a transaction?',
            answer: 'To cancel a transaction please contact your account representative and refer <a href="/policies/andie-guarantee" title="" class="text-blue-600 transition-all duration-200 hover:underline">Andie guarantee</a> for our cancellation policy.',
            open: false
        },
        {
            question: 'How can I report an issue with a transaction?',
            answer: 'Please reach out to your account representative or submit a ticket above to report an issue with a transaction.',
            open: false
        }
    ]);

    const toggleFaq = (index) => {
        setFaq(faq.map((item, i) => {
            if (i === index) {
                item.open = !item.open;
            } else {
                item.open = false;
            }

            return item;
        }));
    }
    

    const [ticketId, setTicketId] = useState(null);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        subject: '',
        ticket_details: '',
        selectedIssue: '',
    });
    
    const issueOptions = [
        { category: 'Booking Issues', issue: 'I cannot cancel my booking' },
        { category: 'Response Issues', issue: 'I cannot add items to a response' },
        { category: 'Response Issues', issue: 'My response is not submitting' },
        { category: 'Food Quality Issues', issue: 'The food is cold' },
        { category: 'Service Issues', issue: 'The restaurant was not hospitable' },
        { category: 'Service Issues', issue: 'The guests were rude' },
    ];
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.async = true;
        script.defer = true;
        script.src = '//js.hs-scripts.com/45605157.js';
        document.body.appendChild(script);

        // Clean-up function (optional)
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel" /> 
                <div className="andie-main-panel overflow-y-scroll scrollbar-hide">
                    <p className="andie-description-text text-xs"></p>
                    <h1 className="text-xl font-productsans-bold">Help Centre</h1>
                    <div className="my-1 h-[2px] w-24 bg-gray-700" />

                    <div className="my-6 border border-gray-100 flex justify-between rounded p-3">
                        <div>
                            <h3 className="font-productsans-bold text-base leading-none">We are here to help 24/7</h3>
                            <p className="andie-description-text">Tell us how we can help and we'll get right on it.</p>
                        </div>
                        <SupportChat toplevel />
                    </div>

                    {ticketId}

                    <div className="grid grid-cols-4 gap-4">
                        {contacts.map((contact, index) => (
                            <div>
                                <div className="h-40 flex items-center justify-center rounded-lg bg-blue-50">
                                    <h6 className="text-base font-productsans-medium">{contact.title}</h6>
                                </div>
                                <div className="mt-2">
                                    <p className="andie-description-text leading-tight">{contact.description}</p>
                                    <div className="mt-4 flex flex-col space-y-1">
                                        <a className="andie-description-text font-roboto-medium text-blue-600 underline" href={`mailto:${contact.email}`}>Email</a>
                                        <a className="andie-description-text font-roboto-medium text-blue-600 underline" href={`tel:${contact.phone}`}>Telephone</a>
                                        <SupportChat />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Link to="/policies/andie-guarantee" className="bg-blue-950 h-40 flex items-center justify-center rounded-lg">
                            <h3 className="font-productsans-medium text-2xl text-white"><span className="text-green-600">pluck</span>guarantee</h3>
                        </Link>
                    </div>

                    <div className="mt-8 pb-16 pt-6 border-t border-gray-100">
                        <h3 className="font-productsans-bold text-base leading-none">Submit a ticket</h3>
                        <div className="flex flex-row-reverse">
                            <div className="hidden md:inline-flex flex-col md:w-1/4">
                                <img src={supporticon} className="w-full" />
                                <div>
                                    <h6 className="font-productsans-medium text-paragraph uppercase text-gray-700">Hours of Operations</h6>

                                    <div className="border-t mt-3 pt-3 space-y-3">
                                        {hoursOfOperation.map((item, index) => (
                                            <div key={index} className="flex justify-between andie-description-text">
                                                <p className="font-roboto-medium">{item.day}</p>
                                                <p>{item.hours}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <form className="mt-6 md:w-3/4 md:pr-4 xl:pr-12 flex flex-col space-y-3">
                                <div className="grid grid-cols-2 gap-x-3">
                                    <label className="bid-form-input-label">
                                        First Name*:
                                        <input className="mt-2 w-full bid-form-input h-9" type="text" name="firstname" value={formData.firstName} onChange={handleChange} required />
                                    </label>
                                    <label className="bid-form-input-label">
                                        Last Name*:
                                        <input className="mt-2 w-full bid-form-input h-9" type="text" name="lastname" value={formData.lastName} onChange={handleChange} required />
                                    </label>
                                </div>

                                <label className="bid-form-input-label">
                                    Email*:
                                    <input className="mt-2 w-full bid-form-input h-9" type="email" name="email" value={formData.email} onChange={handleChange} required />
                                </label>

                                <label className="bid-form-input-label">
                                    Phone*:
                                    <input className="mt-2 w-full bid-form-input h-9" type="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                                </label>

                                <label className="bid-form-input-label">
                                    Subject*:
                                    <input className="mt-2 w-full bid-form-input h-9" type="text" name="subject" value={formData.subject} onChange={handleChange} required />
                                </label>

                                <label className="bid-form-input-label">
                                    Select Issue*:
                                    <select className="mt-2 w-full bid-form-input h-9 py-0" name="selectedIssue" value={formData.selectedIssue} onChange={handleChange} required>
                                    <option value="" disabled>Select an issue</option>
                                    {issueOptions.map((option, index) => (
                                        <optgroup label={option.category} key={index}>
                                        <option value={option.issue}>{option.issue}</option>
                                        </optgroup>
                                    ))}
                                    </select>
                                </label>

                                <label className="bid-form-input-label">
                                    Details*:
                                    <textarea name="ticket_details" value={formData.ticket_details} onChange={handleChange} required className="mt-2 w-full h-24 md:h-32 bid-form-input" />
                                </label>

                                <button type="submit" className="andie-dark-button h-8 w-20">Submit</button>
                            </form>
                        </div>
                    </div>

                    <div className="pb-16 pt-6 border-t border-gray-100">
                        <h3 className="font-productsans-bold text-base leading-none">Frequently Asked Questions</h3>
                        <section className="rounded-xl mt-8 p-6 bg-slate-100">
                            <div className="grid md:grid-cols-2 gap-4">
                                {faq.map((item, index) => (
                                    <div key={index} className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer rounded-md hover:bg-gray-50">
                                        <button type="button" className="flex items-center justify-between w-full p-4" onClick={() => toggleFaq(index)}>
                                            <span className="text-sm text-gray-700 font-productsans-medium"> {item.question} </span>

                                            <svg className={`w-5 h-5 text-gray-400 ${item.open ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </button>

                                        <div className={`${item.open ? 'block' : 'hidden'} px-4 pb-5`}>
                                            <p className="andie-description-text leading-tight w-[90%]" dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <p className="andie-description-text mt-9 text-center">Didn’t find the answer you are looking for? <a href="tel:6479489778" title="" className="font-roboto-medium text-blue-600">Call our support line</a></p>
                        </section>
                    </div>
                </div>
                <SidebarRight />
            </div>
        </div>
    )
}