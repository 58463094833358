import { BookmarkIcon, FlagIcon, MailIcon, PrinterIcon } from '@heroicons/react/outline';
import {BookmarkIcon as BookmarkIconFull } from "@heroicons/react/solid";
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountsAPI, BiddingAPIs } from '../../apis';
import { cge_frontend } from '../../apis/config';

export default function BidTools({ bid, handlePrint }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));
    const [loadingSave, setLoadingSave] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const body = `Please visit this webpage: ${cge_frontend + location.pathname.slice(1)}`;
    const report_body = `The following bid: ${location.pathname.replace('/bid/', '')} requires attention.. 
    ${cge_frontend + location.pathname.slice(1)}`;


    const print = () => {
        handlePrint()
    }

    const handleSaveBid = async (bid_id, save) => {
        if (loadingSave == false) {
            setLoadingSave(true)
            const payload = {
                'bid_id': bid_id
            }
            let tempUser = user
            if (save) {
                await BiddingAPIs.saveBid(payload, user.account_type);
                tempUser.saved_bids?.push(bid_id.toString())
                localStorage.setItem('user_details', JSON.stringify(tempUser))
                setUser(tempUser)
            } else {
                await BiddingAPIs.unsaveBid(payload, user.account_type);
                let savedBidsArr = []
                tempUser.saved_bids?.map((bid) => {
                    if (bid_id != bid) {
                        savedBidsArr.push(bid)
                    }
                })
                tempUser.saved_bids = savedBidsArr
                localStorage.setItem('user_details', JSON.stringify(tempUser))
                setUser(tempUser)
            }
            setLoadingSave(false)
            AccountsAPI.VerifyUserOnLogin();
        }
    }


    return (
        <div className="mb-24 md:mb-0 flex w-full flex-col space-y-2 xl:space-y-3 md:items-end md:justify-end">
            {user !== null ?
                user.account_type === 1 ?
                    <div className="andie-circle-button">
                        {user.saved_bids?.includes(bid.id.toString()) ?
                            <BookmarkIconFull onClick={() => handleSaveBid(bid.id, false)} className="h-4 text-blue-600" />
                            : <BookmarkIcon onClick={() => handleSaveBid(bid.id, true)} className="h-4 text-blue-600" />}
                    </div>
                    : <div></div>
                : <div></div>
            }

            <div onClick={() => window.open(`mailto:?subject=Andie Bid: ${location.pathname.replace('/bid/', '')}&body=${body}`)}>
                {window.innerWidth < 768 ? 
                    <div className="font-productsans-medium text-blue-600 uppercase text-xs">Share this bid</div>
                    :
                    <div className="andie-circle-button">
                        <MailIcon className="h-4 text-blue-600" />
                    </div>
                }
            </div>
            <div onClick={print}>
                {window.innerWidth < 768 ? 
                    <div className="font-productsans-medium text-blue-600 uppercase text-xs">Download PDF</div>
                    :
                    <div className="andie-circle-button">
                        <PrinterIcon className="h-4 text-blue-600" />
                    </div>
                }
            </div>
            <div>
                <div onClick={() => window.open(`mailto:engineering@andie.work?subject=Report on bid: ${location.pathname.replace('/bid/', '')}&body=${report_body}`)} >
                    {window.innerWidth < 768 ? 
                        <div className="font-productsans-medium text-blue-600 uppercase text-xs">Report issue</div>
                        :
                        <div className="andie-circle-button">
                            <FlagIcon className="h-4 text-blue-600" />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}