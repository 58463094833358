import React from "react";
import { WhatsNewIcon } from "../../../assets/icons";

export default function WhatsNew() {
    const whatsnew = [
        { title: "A new look for our bid page.", description: "The bid page has a fresh look to allow you to find bid requirements and additional notes quickly." },
        { title: "Adding staffing requirements to your bid.", description: "You can now request for chefs, servers, and any additional staff during the create a bid process." },
        { title: "Recurring bids.", description: "Hosting the same event regularly? The recurring bid functional allows you to create recurring bids from one entry." },
        { title: "Integrated Messaging.", description: "Need to make changes to a response? Got questions about an item? Message restaurants and caterers from the bid page." }
    ]

    const comingsoon = [
        { title: "Enhanced notifications." },
        { title: "Mobile responsiveness." },
        { title: "Account manager messaging." }
    ]

    return (
        <div className="scrollbar-hide">
            <div className="flex justify-center">
                <WhatsNewIcon height={240} />
            </div>
            <div className="mt-4">
                <div className="border-b pb-2 mb-3 border-gray-100 flex justify-between items-center">
                    <h6 className="text-base font-productsans-bold text-blue-600">v. 1.1.10.</h6>
                    <p className="andie-description-text text-gray-500">March 18, 2024.</p>
                </div>
                
                <div>
                    <p className="andie-description-text mb-3">Welcome to the public release of Andie.</p>

                    <p className="andie-description-text mb-3">Over the last 12 months, our team has used Andie to deliver thousands of great corporate guest experiences. We are thrilled to be able to put you at the wheels of the bidding process as we move forward.</p>

                    <p className="andie-description-text mb-3">A special thank you to the beta testers who have made this release possible. If you will love to become a beta tester, please email us at <span className="font-roboto-medium text-black-600">engineering@hellopluck.com</span>.</p>

                    <div className="mt-7 space-y-3">
                        <h6 className="font-productsans-medium text-blue-600">What's new in this release:</h6>
                        <div className="space-y-5">
                            {whatsnew.map((item, index)  => (
                                <div className="flex space-x-2">
                                    <div className="h-2.5 w-2.5 flex-shrink-0 bg-blue-600 rounded-full" />
                                    <div className="-mt-1.5">
                                        <h6 className="andie-description-text font-roboto-medium text-sm">{item.title}</h6>
                                        <p className="andie-description-text leading-tight text-gray-500">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                    <div className="mt-7 mb-10 space-y-3">
                        <h6 className="font-productsans-medium text-blue-600">Coming Soon:</h6>
                        <div className="space-y-4">
                            {comingsoon.map((item, index)  => (
                                <div className="flex space-x-2">
                                    <div className="h-2.5 w-2.5 flex-shrink-0 bg-blue-600 rounded-full" />
                                    <div className="-mt-1.5">
                                        <h6 className="andie-description-text font-roboto-medium text-sm">{item.title}</h6>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}