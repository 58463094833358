import { round } from "lodash";
import catering from "../../assets/images/sidebar/catering.png";
import cateringplus from "../../assets/images/sidebar/cateringplus.png";
import dining from "../../assets/images/sidebar/dining.png";
import diningplus from "../../assets/images/sidebar/diningplus.png";

function moneySignText(amount) {
    if (amount <= 500) {
        return "$"
    }
    else if (amount > 500 && amount <= 1000) {
        return "$$"
    }
    else if (amount > 1000 && amount <= 10000) {
        return "$$$"
    }
    else {
        return "$$$$"
    }
}

function budgetInference(perHead, event_type) {
    if (event_type.includes("Catering")) {
        if (perHead <= 20) {
            return "Standard"
        }
        else if (perHead > 20 && perHead <= 50) {
            return "Friendly"
        }
        else if (perHead > 50 && perHead <= 200) {
            return "Great"
        }
        else {
            return "Very Generous"
        }
    } else {
        if (perHead <= 40) {
            return "Standard"
        }
        else if (perHead > 40 && perHead <= 100) {
            return "Friendly"
        }
        else if (perHead > 100 && perHead <= 500) {
            return "Great"
        }
        else {
            return "Very Generous"
        }
    }
}

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

function calculateTax(subtotal, serviceFee, delivery_fee) {
    const taxRate = 0.13
    var tax = (subtotal + delivery_fee + serviceFee) * (taxRate)
    return tax
}

function calculateGratuity(subtotal, tipPercent) {
    var tip = subtotal * tipPercent
    return tip
}

function calculateServiceFee(subtotal, service_fee_rate) {
    var fee = subtotal * service_fee_rate;
    return fee
}

function calculateDeliveryFee(delivery_fee, business_delivers, subtotal, delivery_surcharge) {
    if (business_delivers) {
        return delivery_fee * (1 + delivery_surcharge)
    } else {
        return round(35 + (subtotal * 0.01))
    }
}


function getTransactionAmounts(items, tipPercent, delivery, business_delivers, cge_service_fee_rate, business_service_fee_rate, user_type, pricingType, totalPrice) {
    const delivery_surcharge = 0.2
    const user = JSON.parse(window.localStorage.getItem('user_details'))
    let subtotal = calculateResponseSubtotal(items, pricingType, totalPrice)
    let serviceFee = calculateServiceFee(subtotal, cge_service_fee_rate)
    let deliveryFee = calculateDeliveryFee(delivery, business_delivers, subtotal, delivery_surcharge)

    if (user_type == null) {
        if (user) {
            user_type = user.account_type
        } else {
            user_type = 1
        }
    }

    if (user_type === 2) {
        serviceFee = calculateServiceFee(subtotal, business_service_fee_rate) * -1
        deliveryFee = deliveryFee / (1 + delivery_surcharge)
        if (!business_delivers)
            deliveryFee = 0
    }

    let tax = calculateTax(subtotal, serviceFee, deliveryFee)
    let tip = calculateGratuity(subtotal, tipPercent)

    let total = subtotal + serviceFee + deliveryFee + tax + tip

    return ({
        subtotal: subtotal,
        serviceFee: serviceFee,
        deliveryFee: deliveryFee,
        tax: tax,
        tip: tip,
        total: total
    }
    )
}

function calculateBudget(budget, coefficient) {
    return applyCoefficient(budget, coefficient)
}

function calculateResponseSubtotal(items, pricingType, totalPrice) {
    var amount = 0
    if (pricingType == "total") {
        amount = totalPrice
    } else {
        items?.forEach(element => amount += (element.price * element.quantity));
    }
    return amount
}

function applyCoefficient(price, coefficient) {
    return price
}

function pricetoString(price) {
    price = parseFloat(price)
    return price.toLocaleString(
        "en-US",
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            roundingIncrement: 1
        });
}

function calculatePackageItemPricing(pkg, packageQuantities) {
    if (pkg.pricing == "item") {
        let options = Object.keys(pkg.items)
        let total = 0

        for (const option of options){
            for (const item of pkg.items[option]) {
                const linePrice = (parseFloat(item.price)) * parseFloat(item.quantity)
                if (linePrice && linePrice >= 0) total += linePrice
            }
        }
        return total
    } else if (pkg.pricing === "package") {
        return pkg.package_price * (packageQuantities.main / pkg.serves)
    } else {
        return pkg.package_price 
    }
}

function getPackageTransactionAmounts(pkg, packageQuantities, cge_service_fee_rate, user_type) {
    const delivery_surcharge = 0.2
    const user = JSON.parse(window.localStorage.getItem('user_details'))
    const subtotal = calculatePackageItemPricing(pkg, packageQuantities)
    const serviceFee = calculateServiceFee(subtotal, cge_service_fee_rate)

    let deliveryFee = pkg.delivery_fee
    if (user_type == null) {
        if (user) {
            user_type = user.account_type
        } else {
            user_type = 1
        }
    }

    let tax = calculateTax(subtotal, serviceFee, deliveryFee)
    let tip = calculateGratuity(subtotal, 0)
    let total = subtotal + serviceFee + deliveryFee + tax + tip

    return ({
        subtotal: subtotal,
        serviceFee: serviceFee,
        deliveryFee: deliveryFee,
        tax: tax,
        tip: tip,
        total: total
    }
    )
}

function calulatePackageMaxAndMin(packageItems, pricingType, totalPrice) {
    if (pricingType == "item") {
        let options = Object.keys(packageItems)

        let maximum = 0
        let minimum = 0

        for (const option of options) {
            if (packageItems[option].length > 0) {
                let optionmaximum = 0
                let optionminimum = Number.MAX_VALUE
                for (const item of packageItems[option]) {
                    if (optionmaximum < parseFloat(item.price)/parseFloat(item.serves)) optionmaximum = parseFloat(item.price)/parseFloat(item.serves)
                    if (optionminimum > parseFloat(item.price)/parseFloat(item.serves)) optionminimum = parseFloat(item.price)/parseFloat(item.serves)

                }
                maximum += optionmaximum
                minimum += optionminimum
            }
        }

        return {
            max:maximum,
            min:minimum,
            totalPrice:-99999
        }
    } else {
        return {
            max:-99999,
            min:-99999,
            total:totalPrice
        }
    }
}

function calculatePackageTotaPrice(packageItems, pricingType, totalPrice) {
    const finalPrice = calulatePackageMaxAndMin(packageItems, pricingType, totalPrice)
    if (pricingType == "item") {
        if (finalPrice.min === finalPrice.max) return `$${pricetoString(finalPrice.min)}`
        else return `$${pricetoString(finalPrice.min)} - $${pricetoString(finalPrice.max)}`
    } else {
        return "$" + pricetoString(finalPrice.total)
    }
}

function displayPackageDeliverFee(packageItems, pricingType, totalPrice) {
    const finalPrice = calulatePackageMaxAndMin(packageItems, pricingType, totalPrice)
    if (pricingType == "item") {
        return `$${pricetoString(calculateDeliveryFee(0, false, finalPrice.max, 0))}`
    } else {
        return "$" + pricetoString(calculateDeliveryFee(0, false, finalPrice.total, 0))
    }
}


// --------------------------------------------------------------------------------------

const amenityOptions = {
    Elevators: "elevator access",
    Parking: "parking available",
    Sidewalk: "sidewalk access",
    Stairs: "stairs",
    "Ride Share": "accessible by rideshare",
    Transit: "accessible by transit",
    Accessible: "accessibility access",
}

const cgeOptions = [
    { name: "Catering", icon: catering, explanation: "Delicious meals delivered to a location of your choice." },
    { name: "Dining", icon: dining, explanation: "Prepaid dining experiences at one of our partner restaurants." },
    { name: "Catering Plus", icon: cateringplus, explanation: "Servers, multi-restaurant orders, premium utensils, and more." },
    { name: "Premium Dining", icon: diningplus, explanation: "Private rooms, premium beverages, and more." }
];

const foodOptions = [
    // { value: "any", label: "Any", icon: '' },
    { value: "finedining", label: "Fine Dining", icon: '' },
    { value: "premiumcasual", label: "Premium Casual", icon: '' },
    { value: "cafe", label: "Cafe", icon: '' },
    { value: "american", label: "American", icon: '' },
    { value: "italian", label: "Italian", icon: '' },
    { value: "steakhouse", label: "Steakhouse", icon: '' },
    { value: "seafood", label: "Seafood", icon: '' },
    { value: "french", label: "French", icon: '' },
    { value: "indian", label: "Indian", icon: '' },
    { value: "mexican", label: "Mexican", icon: '' },
    { value: "japanese", label: "Japanese", icon: '' },
    { value: "chinese", label: "Chinese", icon: '' },
    { value: "spanish", label: "Spanish", icon: '' },
    { value: "pizzeria", label: "Pizzeria", icon: '' },
    { value: "barbecue", label: "Barbecue", icon: '' },
    { value: "greek", label: "Greek", icon: '' },
    { value: "turkish", label: "Turkish", icon: '' },
    { value: "portuguese", label: "Portuguese", icon: '' },
    { value: "lebanese", label: "Lebanese", icon: '' },
    { value: "brazilian", label: "Brazilian", icon: '' },
    { value: "korean", label: "Korean", icon: '' },
    { value: "moroccan", label: "Moroccan", icon: '' },
    { value: "persian", label: "Persian", icon: '' },
    { value: "vegetarian", label: "Vegetarian", icon: '' },
    { value: "breakfast", label: "Breakfast", icon: '' },
    { value: "jamaican", label: "Jamaican", icon: '' },
    { value: "filipino", label: "Filipino", icon: '' },
    { value: "egyptian", label: "Egyptian", icon: '' },
    { value: "pakistani", label: "Pakistani", icon: '' },
    { value: "deli", label: "Deli", icon: '' },
    { value: "vegan", label: "Vegan", icon: '' },
    // { value: "european", label: "European", icon: '' },
    { value: "african", label: "African", icon: '' },
    { value: "malaysian", label: "Malaysian", icon: '' },
    { value: "mediterranean", label: "Mediterranean", icon: '' },
    { value: "peruvian", label: "Peruvian", icon: '' },
    { value: "pub", label: "Pub", icon: '' },
    { value: "wine_bar", label: "Wine Bar", icon: '' },
    { value: "bistro", label: "Bistro", icon: '' },
    { value: "lounge", label: "Lounge", icon: '' },
    { value: "thai", label: "Thai", icon: '' },
    { value: "latin", label: "Latin", icon: '' },
    { value: "fusion", label: "Fusion", icon: '' },
    { value: "desserts", label: "Desserts", icon: '' },
    { value: "caterer", label: "Caterer", icon: '' },
    { value: "other", label: "Other", icon: '' }
]

const eventOptions = [
    { name: "Office breakfast" },
    { name: "Conference" },
    { name: "Dinner" },
    { name: "Executive breakfast" },
    { name: "Happy hour" },
    { name: "Gala" },
    { name: "Lunch" },
    { name: "Meeting refreshments" },
    { name: "Networking event" },
    { name: "Seminar / Conference" },
    { name: "Product launch" },
    { name: "Team outing" },
    { name: "Client meeting" },
    { name: "Other" }
];

const seatingOptions = [
    "Bar",
    "Counter",
    "Standard",
    "High Top",
    "Outdoor",
]

const packagingOptions = [
    "Any",
    "Buffet / Food Station",
    "Individually"
]

const pages = [
    { name: "Explore", route: "/" },
    { name: "Activity", route: "/activity" },
    { name: "Messages", route: "/messages" },
    { name: "Account", route: "/account" },
];

const priceFilters = [
    { category: 'budget', title: "Less than $25", min: 0, max: 25.00 },
    { category: 'budget', title: "$25.00 - $49.99", min: 25.00, max: 49.99 },
    { category: 'budget', title: "$50.00 - $99.99", min: 50.00, max: 99.99 },
    { category: 'budget', title: "$100.00 - $199.99", min: 100.00, max: 199.99 },
    { category: 'budget', title: "$200.00 and above", min: 200.00, max: 9999999 },
];

const guestFilters = [
    { category: 'guests', title: "Under 25 guests", min: 0, max: 24 },
    { category: 'guests', title: "25 to 50 guests", min: 25, max: 49 },
    { category: 'guests', title: "50 to 100 guests", min: 50, max: 99 },
    { category: 'guests', title: "100 to 200 guests", min: 100, max: 199 },
    { category: 'guests', title: "200 guests and above", min: 200, max: 9999 },
]

const bidTabs = [
    { title: "About", explanation: "Get started" },
    { title: "Bid Details", explanation: "Tell us about your event" },
    { title: "Preferences", explanation: "Select your cuisine and menu preferences" },
    { title: "Dietary Requirements", explanation: "What else should we know" },
    { title: "Budget", explanation: "Budget and payment information" },
    { title: "Contact", explanation: "Contact information" },
    { title: "Confirmation", explanation: "When can you make a decision?" }
]

const currencyOptions = [
    { name: "CAD" },
    { name: "USD" }
]

const paymentOptions = [
    { name: "Prepaid in full" },
    { name: "50% deposit, 50% on completion" },
]

const tipOptions = [
    { name: "None", value: 0 },
    { name: "18%", value: 18 },
    { name: "20%", value: 20 },
    { name: "25%", value: 25 },
]

const industryOptions = [
    { name: "Accounting" },
    { name: "Advertising" },
    { name: "Agriculture" },
    { name: "Automotive" },
    { name: "Banking" },
    { name: "Biotechnology" },
    { name: "Conference" },
    { name: "Construction" },
    { name: "Consulting" },
    { name: "Education" },
    { name: "Energy" },
    { name: "Engineering" },
    { name: "Fashion" },
    { name: "Finance" },
    { name: "Financial Services" },
    { name: "Food and Beverage" },
    { name: "Government" },
    { name: "Grocery" },
    { name: "Healthcare" },
    { name: "Hospitality" },
    { name: "Insurance" },
    { name: "Information Technology" },
    { name: "Investment Banking" },
    { name: "IT Services" },
    { name: "Legal Services" },
    { name: "Manufacturing" },
    { name: "Marketing" },
    { name: "Media and Entertainment" },
    { name: "Mining" },
    { name: "Not-for-Profit" },
    { name: "Pharmaceuticals" },
    { name: "Real Estate" },
    { name: "Retail" },
    { name: "Startup / Tech" },
    { name: "Telecommunications" },
    { name: "Transportation" },
    { name: "Travel" },
    { name: "Utilities" },
    { name: "Other" }
];

const findOptions = [
    { label: "Select an option" },
    { label: "Instagram or Facebook" },
    { label: "Email or newsletter" },
    { label: "Search engine" },
    { label: "Word of mouth" },
    { label: "YouTube" },
    { label: "Andie Sales Team" },
    { label: "Other" },
];

const dietaryOptions = [
    { preference: "None", value: "None" },
    { preference: "Vegan" },
    { preference: "Vegetarian" },
    { preference: "Halal" },
    { preference: "Kosher" },
    { preference: "Gluten free" },
    { preference: "Nut free" },
    { preference: "Dairy free" },
    { preference: "Shellfish free" },
    { preference: "Keto" },
    { preference: "Pescetarian" },
    { preference: "Lactose free" },
    { preference: "Other" }
];

const extraCategories = [
    { name: "New & Noteworthy" },
    { name: "Popular & Trending" },
    { name: "Premium" },
];

const atmosphereOptions = [
    { value: "casual", label: "Casual" },
    { value: "business casual", label: "Business Casual" },
    { value: "formal", label: "Formal" },
    { value: "upscale", label: "Upscale" },
]

const starOptions = [
    { value: "Any", title: "Any" },
    { value: "3.0 - 4.0 stars", title: "3.0 - 4.0 stars" },
    { value: "4.0 - 5.0 stars", title: "4.0 - 5.0 stars" }
]

const statusOptions = [
    { name: "Pending" },
    { name: "Confirmed" },
    { name: "Completed" },
    { name: "Paid" },
];


const menuoptions = [
    { value: "appetizer", title: "Appetizer and Shareables", explanation: "Small plates, shareable bites, and mouthwatering finger foods." },
    { value: "main", title: "Main", explanation: "The primary dish that takes your taste buds on an experience." },
    { value: "side", title: "Side", explanation: "Perfect companions to your main dish, these sides add flavor and variety to your meal." },
    { value: "dessert", title: "Dessert", explanation: "Delicious, sweet, or savory treats that leave you wanting more." },
    { value: "beverages", title: "Beverages", explanation: "Alcoholic or non-alcoholic drinks to help quench your thirst." },
    { value: "other", title: "Other", explanation: "Unique and special items that enhance your dining experience." }
]

const menuoptionssort = {
    "Appetizer and Shareables": 1,
    "Main": 2,
    "Side": 3,
    "Dessert": 4,
    "Beverages": 5,
    "Other": 6,
} 

const dietaryLabels = {
    "Vegetarian": "V",
    "Vegan": "VE",
    "Gluten free": "GF",
    "Nut free": "NF",
    "Halal": "H",
    "Dairy free": "DF",
    "Lactose free": "LF",
    "Kosher": "K",
    "Shellfish free": "SF",
    "Keto": "KETO",
    "Pescetarian": "P"
}


function modalSize() {
    if (window.innerWidth < 768) {
        return {
            modal: {
                backgroundColor: "white",
                boxShadow: "none",
                padding: "0",
                margin: "0",
                width: "100%",
                height: "75%",
                overflow: "hidden",
                position: "fixed",
                bottom: "0",
                left: " 0%",
                right: "0%",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
            },
        };
    } else if (window.innerWidth > 768 && window.innerWidth < 1024) {
        return {
            modal: {
                boxShadow: "none",
                padding: "0",
                margin: "0",
                width: "40%",
                height: "100%",
                position: "fixed",
                overflow: "hidden",
                right: "0%",
                top: "0%",
                borderRadius: "2px",
            },
        };
    } else {
        return {
            modal: {
                boxShadow: "none",
                padding: "0",
                margin: "0",
                width: "26%",
                height: "100%",
                position: "fixed",
                overflow: "hidden",
                right: "0%",
                top: "0%",
                borderRadius: "2px",
            },
        };
    }
}

const bidStatusColors = {
    "Posted": "bg-orange-200",
    "Expired": "bg-gray-600 text-white",
    "Closed": "bg-red-500 text-white",
    "Accepted": "bg-green-600 text-white",
    "Completed": "bg-indigo-600 text-white"
}

const headerBlacklist = [
    "231754-trn-cge",
    "login",
    "success-request",
    "payment",
    "password",
    "signup",
    "9CzFO4REGlf93l2bDHEnpqjDg"
]

export {
    getTransactionAmounts,
    moneySignText,
    pad,
    calculateGratuity,
    calculateBudget,
    pricetoString,
    calculateResponseSubtotal,
    calculateServiceFee,
    calculateTax,
    calculateDeliveryFee,
    getPackageTransactionAmounts,
    calulatePackageMaxAndMin,
    calculatePackageTotaPrice,
    displayPackageDeliverFee,
    foodOptions,
    eventOptions,
    seatingOptions,
    packagingOptions,
    cgeOptions,
    amenityOptions,
    pages,
    priceFilters,
    guestFilters,
    bidTabs,
    currencyOptions,
    paymentOptions,
    industryOptions,
    findOptions,
    dietaryOptions,
    tipOptions,
    extraCategories,
    atmosphereOptions,
    starOptions,
    statusOptions,
    menuoptions,
    menuoptionssort,
    dietaryLabels,
    modalSize,
    bidStatusColors,
    budgetInference,
    headerBlacklist
}